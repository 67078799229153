import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import YieldItem from './YieldItem'
import YieldItemMobile from './YieldItemMoblie'
import TotalLocked from './TotalLocked'
import useMobile from '../../../hooks/useMobile'
import { useLPInfo } from '../../../hooks/useLPPoolInfo'
import NoDataImg from '../../../assets/img/nodata.png'

const YieldList: React.FC = () => {
  const [currentPool, setCurrentPool] = useState<any[] | undefined>([])
  const isMobile = useMobile()
  const pool = useLPInfo()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const onChangePage = (page: number, pageSize: number) => {
    console.log(page, pageSize)
    setCurrentPage(page)
  }
  useEffect(() => {
    console.log(333, pool)
    if (pool?.length) {
      setCurrentPool(pool?.slice(0, 10))
    } else {
      setCurrentPool([])
    }
  }, [pool])
  return (
    <>
      {!isMobile ? (
        <StyledYieldWarp>
          <StyledTableHead>
            <Row align={'middle'} className={'y-head'} gutter={[8, 0]}>
              <Col className={'yh-col y-center'} span={3}>
                Pool
              </Col>
              <Col className={'yh-col'} span={4}>
                Assets
              </Col>
              <Col className={'yh-col y-right'} span={3}>
                Paired
              </Col>
              <Col className={'yh-col y-right'} span={4}>
                In Queue
              </Col>
              <Col className={'yh-col y-right'} span={4}>
                Queue Gap
              </Col>
              {/*<Col className={'yh-col y-right'} span={5}>
                Max ROI / My ROI
              </Col>*/}
              <Col className={'yh-col y-right'} span={3}>
                My TVL
              </Col>
              <Col className={'yh-col y-right'} span={3}>
                Token Gain
              </Col>
            </Row>
          </StyledTableHead>
          <StyledTableBody>
            {currentPool?.length ? (
              currentPool.map((item, index) => {
                return <YieldItem poolInfo={item} key={index} />
              })
            ) : (
              <StyledNoData>
                <StyledNDIcon>
                  <img src={NoDataImg} alt="" />
                </StyledNDIcon>
                <StyledNDText>No Data</StyledNDText>
              </StyledNoData>
            )}
          </StyledTableBody>
        </StyledYieldWarp>
      ) : (
        <StyledYieldMobileWarp>
          {currentPool?.length ? (
            currentPool.map((item, index) => {
              return <YieldItemMobile pool={pool} key={index} />
            })
          ) : (
            <StyledNoData>
              <StyledNDIcon>
                <img src={NoDataImg} alt="" />
              </StyledNDIcon>
              <StyledNDText>No Data</StyledNDText>
            </StyledNoData>
          )}
        </StyledYieldMobileWarp>
      )}
      <TotalLocked />
    </>
  )
}

const StyledYieldWarp = styled.div`
  position: relative;
  margin-top: 24px;
  background-color: #1c2337;
  border: 2px solid;
  border-image: linear-gradient(94.48deg, #e001e5 5.16%, #19d9f5 91.31%) 1;
  clip-path: inset(0 round 4px);
  z-index: 1;
  .y-head {
    padding: 0 24px;
  }
  .yh-col {
    padding: 24px 0;
    color: #d8ced8;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 1px;
  }
  .yb-col {
    padding: 30px 0;
    cursor: pointer;
  }
  .y-center {
    text-align: center;
  }
  .y-right {
    text-align: right;
  }
  .y-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .farm-expand {
    height: 340px;
  }
`

const StyledTableHead = styled.div`
  border-bottom: 1px solid;
  border-image: linear-gradient(94.48deg, #e001e5, #19d9f5) 1;
  background-image: linear-gradient(to right, #2d1642, #192744);
`

const StyledTableBody = styled.div``

const StyledNoData = styled.div`
  padding: 50px;
  margin: 0 auto;
  width: 50%;
  text-align: center;
`

const StyledNDIcon = styled.div`
  width: 100px;
  margin: 0 auto 10px;
  & > img {
    width: 100%;
  }
`

const StyledNDText = styled.div`
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 600;
`

const StyledYieldMobileWarp = styled.div`
  position: relative;
  margin-top: 20px;
  z-index: 1;
`

export default YieldList
