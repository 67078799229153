// lib
import React, { useState } from 'react'
import styled from 'styled-components'
import YieldExpandedPool from './YieldExpandPool'
import YieldBtn from './YieldBtn'
import YieldExpandedMyDetail from './YieldExpandMyDetail'

// hooks
import { useTranslation } from 'react-i18next'
import useMobile from '../../../hooks/useMobile'

interface ExpandRow {
  isExpanding: boolean
  pool: any
  poolData: any
}

const YieldExpandedRow: React.FC<ExpandRow> = ({ pool, poolData, isExpanding }) => {
  const { t } = useTranslation()
  const [tabName, setTabName] = useState<string>('pool')

  const handleTabChange = (name: string) => {
    setTabName(name)
  }

  return (
    <StyleContent className={isExpanding ? 'farm-expand' : ''}>
      <StyleWrapper>
        <StyledTabGroup>
          <StyledTabBtn className={tabName === 'pool' ? 'tabActive' : ''} onClick={() => handleTabChange('pool')}>
            Pool Stats
          </StyledTabBtn>
          <StyledTabBtn className={tabName === 'my' ? 'tabActive' : ''} onClick={() => handleTabChange('my')}>
            My Details
          </StyledTabBtn>
        </StyledTabGroup>
        <StyledTabWarp>
          {tabName === 'pool' ? (
            <YieldExpandedPool pool={pool} poolData={poolData} />
          ) : (
            <YieldExpandedMyDetail pool={pool} poolData={poolData} />
          )}
        </StyledTabWarp>
        <StyledTips>Earning data updates when user deposit/withdraw or everyday 1 Hr</StyledTips>
        <YieldBtn pool={pool} isApprove={true} />
      </StyleWrapper>
    </StyleContent>
  )
}

const StyleContent = styled.div`
  height: 0px;
  overflow: hidden;
  transition: all 0.3s;
`

const StyleWrapper = styled.div`
  padding: 24px;
  background: #0f1824;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  .tabActive {
    background-color: rgba(255, 255, 255, 0.3);
    color: #fff5ff;
  }
`

const StyledTabGroup = styled.div`
  display: flex;
  align-items: center;
  color: #b9b3b9;
`

const StyledTabBtn = styled.div`
  padding: 4px 32px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-bottom: none;
  transition: all 0.3s;
  letter-spacing: 1px;
  cursor: pointer;
  &:first-of-type {
    border-right: none;
    border-radius: 4px 0 0 0;
  }
  &:last-of-type {
    border-radius: 0 4px 0 0;
  }
`

const StyledTabWarp = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 0 4px 4px 4px;
`

const StyledTips = styled.div`
  margin-top: 8px;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: rgba(255, 255, 255, 0.5);
`

export default YieldExpandedRow
