import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import { getAccountInfo, getWalletConnectState, getWalletAddress } from './action'
import { useWallet, useConnection } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'

export function useWalletState(): AppState['wallet'] {
  return useSelector<AppState, AppState['wallet']>((state) => state.wallet)
}

export function useWalletActionHandlers(): {
  walletConnectState: (isConnected: boolean) => void
  walletAddress: (address: string) => void
} {
  const dispatch = useDispatch<AppDispatch>()
  const walletConnectState = useCallback(
    (isConnected: boolean) => {
      dispatch(getWalletConnectState({ isConnected }))
    },
    [dispatch]
  )

  const walletAddress = useCallback(
    (address: string) => {
      dispatch(getWalletAddress({ address }))
    },
    [dispatch]
  )

  return {
    walletConnectState,
    walletAddress,
  }
}
