import React from 'react'
import { IconProps } from '../Icon'

const CloseIcon: React.FC<IconProps> = ({ color, size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill={color}>
      <rect x="5.63574" y="3.51477" width="21" height="3" transform="rotate(45 5.63574 3.51477)" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3636 3.51477L20.485 5.63609L5.63573 20.4853L3.51441 18.364L18.3636 3.51477Z"
        fill={color}
      />
    </svg>
  )
}

export default CloseIcon
