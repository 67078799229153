import React from 'react'
import { IconProps } from '../Icon'

const SwitchIcon: React.FC<IconProps> = ({ color, size = 18 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path d="M18 0H0V18H18V0Z" fill="white" fillOpacity="0.01" />
      <path d="M15.75 7.125H2.25" stroke="#B9B3B9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.25 2.625L15.75 7.125"
        stroke="#B9B3B9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.54956 10.875H16.0496"
        stroke="#B9B3B9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.54956 10.875L7.04958 15.375"
        stroke="#B9B3B9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SwitchIcon
