import { useCallback, useEffect, useState } from 'react'
import { createFactory } from '@soh-cool/client-sdk'
// import { createFactory } from '../client-js/factory'
import { Direction, PoolType } from '@soh-cool/client-sdk/dist/types'
// import { Direction, PoolType } from '../client-js/types'
import { useWalletState } from '../store/wallet/hooks'
import { useWallet } from '@solana/wallet-adapter-react'
import { programId } from '../utils/utils'
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js'
import BigNumber from 'bignumber.js'
import { PoolTokenPairBase } from '@soh-cool/client-sdk/dist/pools/types'

interface PoolDataProps {
  tokenAQuickPair: string | number
  tokenBQuickPair: string | number
  tokenAPair: string | number
  tokenBPair: string | number
  tokenAInQueue: string | number
  tokenBInQueue: string | number
  tokenASum: string | number
  tokenBSum: string | number
  tokenAIL: string | number
  tokenBIL: string | number
  tokenAILCom: string | number
  tokenBILCom: string | number
  userAPair: string | number
  userAQueue: string | number
  myAPro: string | number
  myAEarn: string | number
  myADeposit: string | number
  myAIL: string | number
  myAILCom: string | number
  userMaxWithdraw: string | number
}

export const usePoolDataInfo = () => {
  const [poolData, setPoolData] = useState<PoolDataProps | null>(null)
  // const [thirdPartyInfo, setThirdPartyInfo] = useState<any>()
  // const [silPoolLPInfo, setSilPoolLPInfo] = useState<any>()
  // const [silPoolQueueInfo, setSilPoolQueueInfo] = useState<any>()
  // const [userPoolInfo, setUserPoolInfo] = useState<any>()
  // const { address, isConnected } = useWalletState()
  const { adapter } = useWallet()
  const raydiumFactory = createFactory(PoolType.Raydium)
  const poolOper = raydiumFactory.getPoolOper()
  const ProgramId = new PublicKey(programId)

  const fetchPool = async (symbol: string) => {
    if (adapter?.publicKey) {
      let thirdPartyInfo, silPoolLPInfo, silPoolQueueInfo, userPoolInfo
      const userOper = raydiumFactory.getUserOper(adapter.publicKey, ProgramId)
      try {
        thirdPartyInfo = await poolOper.getThirdPartyInfo(symbol)
      } catch (e) {
        console.error(e)
      }
      try {
        silPoolLPInfo = await poolOper.getSohPoolLPInfo(symbol, ProgramId)
      } catch (e) {
        console.error(e)
      }
      try {
        silPoolQueueInfo = await poolOper.getSohPoolQueueInfo(symbol, ProgramId)
      } catch (e) {
        console.error(e)
      }
      try {
        userPoolInfo = await userOper.getUserPoolInfo(Direction.AToB, symbol, PoolType.Raydium, ProgramId)
      } catch (e) {
        console.error(e)
      }
      if (thirdPartyInfo && silPoolQueueInfo && silPoolLPInfo && userPoolInfo) {
        const userTokenProportion = userPoolInfo.userProportion.toNumber()
        const userEarn = userPoolInfo.estBonusAmount.toNumber()
        const userStakeAmount = userPoolInfo.userStakeAmount.dividedBy(LAMPORTS_PER_SOL).toNumber()
        const userMaxWithdraw = userPoolInfo.estMaxCanWithdrawCoinAmount.dividedBy(LAMPORTS_PER_SOL).toNumber()
        console.log('userTokenProportion', userTokenProportion, userEarn, userStakeAmount, userMaxWithdraw)
        const tokenProportion = +thirdPartyInfo.totalTokenAAmount / +thirdPartyInfo.totalTokenBAmount
        const tokenAPair = +silPoolLPInfo.totalLPAmount * tokenProportion
        const tokenBPair = +silPoolLPInfo.totalLPAmount * (1 - tokenProportion)
        const tokenAInQueue = new BigNumber(silPoolQueueInfo.tokenAInQueue).dividedBy(LAMPORTS_PER_SOL).toNumber()
        const tokenBInQueue = new BigNumber(silPoolQueueInfo.tokenBInQueue).dividedBy(LAMPORTS_PER_SOL).toNumber()
        const tokenASum = +tokenAPair + +tokenAInQueue
        const tokenBSum = +tokenBPair + +tokenBInQueue
        const tokenAQuickPair = silPoolQueueInfo.tokenBInQueue === '0' ? '0' : tokenBInQueue * tokenProportion
        const tokenBQuickPair = silPoolQueueInfo.tokenAInQueue === '0' ? '0' : tokenAInQueue / tokenProportion
        const sumA = new BigNumber(silPoolQueueInfo.sum_of_token_a_amount).dividedBy(LAMPORTS_PER_SOL).toNumber()
        const sumB = new BigNumber(silPoolQueueInfo.sum_of_token_b_amount).dividedBy(LAMPORTS_PER_SOL).toNumber()
        const tokenAIL = tokenASum - sumA
        const tokenBIL = tokenBSum - sumB
        const tokenAILCom = sumB - tokenBSum
        const tokenBILCom = sumA - tokenASum
        const userAPair = tokenAPair * userTokenProportion
        const userAQueue = tokenAInQueue * userTokenProportion

        setPoolData({
          tokenAQuickPair: Number(tokenAQuickPair).toFixed(4),
          tokenBQuickPair: Number(tokenBQuickPair).toFixed(4),
          tokenAPair: tokenAPair.toFixed(4),
          tokenBPair: tokenBPair.toFixed(4),
          tokenAInQueue: tokenAInQueue,
          tokenBInQueue: tokenBInQueue,
          tokenASum: tokenASum.toFixed(4),
          tokenBSum: tokenBSum.toFixed(4),
          tokenAIL: tokenAIL.toFixed(4),
          tokenBIL: tokenBIL.toFixed(4),
          tokenAILCom: tokenAILCom.toFixed(4),
          tokenBILCom: tokenBILCom.toFixed(4),
          userAPair: userAPair.toFixed(4),
          userAQueue: userAQueue,
          myAPro: (userTokenProportion * 100).toFixed(4),
          myAEarn: userEarn,
          myADeposit: userStakeAmount,
          myAIL: (tokenAIL * userTokenProportion).toFixed(4),
          myAILCom: (tokenAILCom * userTokenProportion).toFixed(4),
          userMaxWithdraw: userMaxWithdraw,
        })
      } else {
        setPoolData(null)
      }
    } else {
      console.log('publicKey is NULL')
    }
    console.log('poolData 000', poolData)
    return poolData
  }
  return { fetchPool }
}
