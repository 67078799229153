import React from 'react'
import { IconProps } from '../Icon'

const GithubIcon: React.FC<IconProps> = ({ color, size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <rect width="24" height="24" rx="4" fill="url(#paint0_linear_2683_3347)" />
      <rect
        x="0.25"
        y="0.25"
        width="23.5"
        height="23.5"
        rx="3.75"
        stroke="url(#paint1_linear_2683_3347)"
        strokeWidth="0.5"
      />
      <path
        d="M12.0013 5.00045C10.339 5.00008 8.73086 5.60628 7.46465 6.71057C6.19844 7.81486 5.35684 9.34515 5.09047 11.0276C4.8241 12.71 5.15035 14.4346 6.01083 15.8929C6.87131 17.3512 8.20984 18.448 9.78686 18.9868C10.1369 19.0497 10.2681 18.834 10.2681 18.6458C10.2681 18.475 10.2591 17.91 10.2591 17.3092C8.50037 17.641 8.0447 16.8695 7.90545 16.466C7.75007 16.0733 7.5038 15.7253 7.18789 15.4521C6.94302 15.3176 6.5917 14.9853 7.17938 14.9766C7.40322 15.0015 7.61789 15.0814 7.80522 15.2095C7.99255 15.3376 8.14702 15.5101 8.25551 15.7124C8.35123 15.8887 8.47995 16.0439 8.63429 16.1692C8.78863 16.2944 8.96557 16.3872 9.15495 16.4422C9.34432 16.4972 9.54241 16.5133 9.73788 16.4897C9.93335 16.4661 10.1224 16.4031 10.2941 16.3045C10.3241 15.9393 10.4827 15.5978 10.7404 15.3438C9.18301 15.1644 7.5554 14.5452 7.5554 11.8C7.54577 11.0867 7.80248 10.3967 8.27296 9.87127C8.05859 9.25136 8.08358 8.57087 8.3428 7.96924C8.3428 7.96924 8.92877 7.78061 10.2676 8.705C11.413 8.38213 12.622 8.38213 13.7673 8.705C15.1062 7.77188 15.6922 7.96924 15.6922 7.96924C15.9514 8.57087 15.9764 9.25136 15.762 9.87127C16.234 10.3958 16.4909 11.0864 16.4796 11.8C16.4796 14.5543 14.8434 15.1622 13.2857 15.3438C13.4527 15.5174 13.5814 15.7259 13.6629 15.955C13.7444 16.1841 13.7768 16.4286 13.7579 16.6717C13.7579 17.6323 13.7494 18.4034 13.7494 18.6453C13.7494 18.8335 13.8806 19.058 14.2306 18.9864C15.8048 18.4431 17.1394 17.3439 17.9962 15.885C18.853 14.4261 19.1762 12.7023 18.9081 11.0216C18.6401 9.34093 17.7981 7.81265 16.5326 6.70964C15.2671 5.60662 13.6605 5.00067 11.9996 5L12.0013 5.00045Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear_2683_3347" x1="2" y1="1.5" x2="24" y2="24" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.13" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear_2683_3347" x1="1.25" y1="2" x2="23" y2="24" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A7A8A8" />
          <stop offset="0.536458" stopColor="white" stopOpacity="0.18" />
          <stop offset="1" stopColor="white" stopOpacity="0.9" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default GithubIcon
