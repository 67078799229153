import { useCallback, useState } from 'react'
import { createFactory } from '@soh-cool/client-sdk'
// import { createFactory } from '../client-js/factory'
import { Direction, PoolType } from '@soh-cool/client-sdk/dist/types'
// import { Direction, PoolType } from '../client-js/types'
import { useWallet } from '@solana/wallet-adapter-react'
import { programId, sendTransaction } from '../utils/utils'
import { Account, PublicKey, Signer, Transaction } from '@solana/web3.js'
import { NATIVE_SOL, TOKENS } from '../utils/token'
import { createSOLTokenAccount } from '../utils/utils'
import { PoolTokenPairBase } from '@soh-cool/client-sdk/dist/pools/types'
// import { PoolTokenPairBase } from '../client-js/pools/types'
import { closeAccount } from '@project-serum/serum/lib/token-instructions'
import * as globalStore from '@soh-cool/client-sdk/dist/globalStore'
// import * as globalStore from '../client-js/globalStore'
// import {
//   createAssociatedTokenAccountIfNotExist,
//   createNativeWrapAssociatedAccountIfNotExistV1,
// } from '../client-js/utils/splToken'
import {
  createAssociatedTokenAccountIfNotExist,
  createNativeWrapAssociatedAccountIfNotExistV1,
} from '@soh-cool/client-sdk/dist/utils/splToken'
import { NATIVE_MINT } from '@solana/spl-token'

const useDeposit = () => {
  const { wallet, connected, publicKey, adapter } = useWallet()
  const transaction = new Transaction()
  const signers: Signer[] = []
  const ProgramId = new PublicKey(programId)
  const raydiumFactory = createFactory(PoolType.Raydium)
  const connection = globalStore.getConnection()
  const handleDeposit = useCallback(
    async (pool: PoolTokenPairBase, amount: string, direction: Direction) => {
      if (adapter?.publicKey) {
        const userOper = raydiumFactory.getUserOper(adapter.publicKey, ProgramId)
        /*if (mintAddress === NATIVE_MINT.toString()) {
          console.log('sourse', amount)
          sourceAccount = await createNativeWrapAssociatedAccountIfNotExistV1(adapter.publicKey, +amount)
          // sourceAccount = await createSOLTokenAccount(connection, adapter.publicKey, amount, token)
          console.log('sourceAccount create success', sourceAccount.toBase58())
        } else {
          sourceAccount = await createAssociatedTokenAccountIfNotExist(
            new PublicKey(mintAddress),
            adapter.publicKey,
            adapter.publicKey
          )
          console.log('sourceAccount create success (else)', sourceAccount.toBase58())
          // sourceAccount = new PublicKey()
        }
        // console.log('depo参数', sourceAccount.toBase58(), pool, PoolType.Raydium, amount, direction)*/
        console.log('direction', direction)
        const result = await userOper.deposit(pool, PoolType.Raydium, amount, direction)
        console.log('result', result)
        /*if (mintAddress === NATIVE_MINT.toString() && adapter) {
          transaction.add(
            closeAccount({
              source: sourceAccount,
              destination: adapter.publicKey,
              owner: adapter.publicKey,
            })
          )
          const txid = await sendTransaction(connection, adapter, transaction)
          console.log('closeAccount', txid)
        }*/
      } else {
        console.log('publicKey is NULL')
      }
    },
    [connected, publicKey, adapter]
  )
  return { onDeposit: handleDeposit }
}
export default useDeposit
