import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Modal, { ModalProps } from '../../../components/Modal'
import ModalContent from '../../../components/ModalContent'
import AmountInputContent from '../../../components/AmountInputContent'
import ModalTitle from '../../../components/ModalTitle'
import { useTranslation } from 'react-i18next'
import useMobile from '../../../hooks/useMobile'
import { CloseIcon } from '../../../components/IconImage'
import useDeposit from '../../../hooks/useDeposit'
import { Direction } from '@soh-cool/client-sdk/dist/types'
// import { Direction } from '../../../client-js/types'
// import * as globalStore from '../../../client-js/globalStore'
import * as globalStore from '@soh-cool/client-sdk/dist/globalStore'
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js'
import useBlock from '../../../hooks/useBlock'
import { useWallet } from '@solana/wallet-adapter-react'
import { SuccessNotice } from '../../../components/Notice'
import { Button } from 'antd'

interface DepositProp extends ModalProps {
  poolInfo: any
}

const YieldDepositModal: React.FC<DepositProp> = ({ poolInfo, onDismiss, maxWidth = 560 }) => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const block = useBlock()
  const { wallet, publicKey, adapter } = useWallet()
  const percentList = ['25%', '50%', '75%', '100%']
  const [typeName, setTypeName] = useState<string>('Deposit')
  const [tabName, setTabName] = useState<string>('0')
  const [depAmount, setDepAmount] = useState<string>('')
  const [solBalance, setSolBalance] = useState<number>(0)
  const [disabledBtn, setDisabledBtn] = useState<boolean>(false)
  const { onDeposit: handleDeposit } = useDeposit()

  const handleAmountVal = useCallback((val) => {
    console.log('val', val)
    setDepAmount(val)
  }, [])

  const handleDepositClick = async () => {
    // setTypeName('Deposit')
    console.log('dep', depAmount)
    if (poolInfo) {
      setDisabledBtn(true)
      const aaa = await handleDeposit(poolInfo, depAmount, Direction.AToB)
      console.log(9900, aaa)
      setDisabledBtn(false)
      onDismiss!()
      SuccessNotice('Success', 'Deposit Success')
    }
  }

  useMemo(async () => {
    if (adapter?.publicKey) {
      const balance = await globalStore.getConnection().getBalance(adapter.publicKey)
      console.log(adapter.publicKey.toBase58(), balance / LAMPORTS_PER_SOL)
      setSolBalance(balance / LAMPORTS_PER_SOL)
    }
  }, [globalStore, adapter])

  return (
    <StakeModalWrapper
      isMobile={isMobile}
      className={isMobile ? 'center-body' : ''}
      style={isMobile ? { width: '100%' } : { minWidth: '420px' }}
    >
      <Modal maxWidth={maxWidth}>
        <ModalTitle>
          <StyledTitle>Deposit</StyledTitle>
          <StyledCloseIconWrapper onClick={() => onDismiss!()}>
            <CloseIcon color="#B0B1B8" size={24} />
          </StyledCloseIconWrapper>
        </ModalTitle>
        <ModalContent>
          <StyledTabGroup>
            <StyledTabBtn
              isMobile={isMobile}
              className={tabName === '0' ? 'active' : ''}
              onClick={() => setTabName('0')}
            >
              {poolInfo.otherInfo.coin.symbol}
            </StyledTabBtn>
            <StyledTabBtn
              isMobile={isMobile}
              className={tabName === '1' ? 'active' : ''}
              onClick={() => setTabName('1')}
            >
              {poolInfo.otherInfo.pc.symbol}
            </StyledTabBtn>
          </StyledTabGroup>
          <AmountInputContent
            getAmountVal={handleAmountVal}
            pool={poolInfo}
            percentList={percentList}
            balance={solBalance}
            type={'deposit'}
          />
          <StyledButtonWarp>
            {/*<BusinessButton type={typeName} disabled={disabledBtn} onClick={handleDepositClick} />*/}
            <BtnWarp>
              {disabledBtn ? (
                <Button className={'btn'} disabled={disabledBtn}>
                  <Dots>{typeName}</Dots>
                </Button>
              ) : (
                <Button className={'btn'} onClick={handleDepositClick} disabled={disabledBtn}>
                  {typeName}
                </Button>
              )}
            </BtnWarp>
          </StyledButtonWarp>
        </ModalContent>
      </Modal>
    </StakeModalWrapper>
  )
}

interface MobileProps {
  isMobile: boolean
}

const StakeModalWrapper = styled.div<MobileProps>`
  &.center-body > div {
    justify-content: center;
    position: relative;
    max-height: initial;
    margin: ${(props) => (props.isMobile ? '0 20px' : '0 auto')};
  }
`

const StyledCloseIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const StyledTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #fff5ff;
`

const StyledTabGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  .active {
    color: #fff5ff;
    background-color: rgba(255, 255, 255, 0.3);
  }
`

const StyledTabBtn = styled.div<MobileProps>`
  width: 25%;
  padding: 4px 0;
  color: #b9b3b9;
  font-size: ${(props) => (props.isMobile ? '12px' : '14px')};
  font-weight: 600;
  line-height: ${(props) => (props.isMobile ? '22px' : '30px')};
  text-align: center;
  cursor: pointer;
  background-color: rgba(31, 19, 31, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.6);
  &:first-of-type {
    border-right: none;
    border-radius: 4px 0 0 4px;
  }
  &:last-of-type {
    border-radius: 0 4px 4px 0;
  }
`

const StyledButtonWarp = styled.div`
  margin: 32px auto 0;
  padding-bottom: 8px;
  width: 70%;
  text-align: center;
`

const BtnWarp = styled.div`
  .btn {
    width: 100%;
    height: 40px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    background-color: transparent;
    background-image: linear-gradient(94.19deg, #e000e4 4.18%, #14def5 88.14%);
    border-radius: 4px;
    border: none;
  }
  .ant-btn[disabled] {
    color: rgba(100, 90, 105, 1);
    background: transparent;
    border: 1px solid #645a69;
  }
`

const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

export default YieldDepositModal
