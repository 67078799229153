import React from 'react'
import { IconProps } from '../Icon'

const FilterIcon: React.FC<IconProps> = ({ color }) => {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill={color}>
      <path
        d="M0.777772 0H13.2222C13.6518 0 14 0.373091 14 0.833326C14 1.29356 13.6518 1.66665 13.2222 1.66665H0.777772C0.348219 1.66665 0 1.29356 0 0.833326C0 0.373091 0.348219 -1.67668e-08 0.777772 0ZM2.33333 4.16666H11.6667C12.0962 4.16666 12.4444 4.53976 12.4444 4.99999C12.4444 5.46023 12.0962 5.83332 11.6667 5.83332H2.33333C1.90378 5.83332 1.55556 5.46023 1.55556 4.99999C1.55556 4.53976 1.90378 4.16666 2.33333 4.16666ZM4.55658 8.33335H9.44343C9.87299 8.33335 10.2212 8.70644 10.2212 9.16667C10.2212 9.62691 9.87297 10 9.44343 10H4.55658C4.12703 10 3.77881 9.62691 3.77881 9.16667C3.77881 8.70644 4.12703 8.33335 4.55658 8.33335Z"
        fill="#D8CED8"
      />
    </svg>
  )
}

export default FilterIcon
