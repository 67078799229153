import React from 'react'
import { IconProps } from '../Icon'

const ContactUsIcon: React.FC<IconProps> = ({ color, size = 32 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none">
      <path
        d="M13.8889 27.2107C10.5221 26.0245 7.71395 21.9652 6.30848 19.5875C6.00012 18.5 6.0213 17.2999 6.99518 16.5658C8.0807 15.7477 9.64554 15.8293 10.6285 16.7554L11.7516 17.8136V5.67806C11.7516 4.7513 12.549 4 13.5327 4C14.5163 4 15.3137 4.7513 15.3137 5.67806V11.1397C15.3137 10.2129 16.1111 9.46162 17.0948 9.46162C18.0784 9.46162 18.8758 10.2129 18.8758 11.1397V12.4821C18.8758 11.5554 19.6732 10.8041 20.6569 10.8041C21.6405 10.8041 22.4379 11.5554 22.4379 12.4821V13.8246C22.4379 12.8978 23.2354 12.1465 24.219 12.1465C25.2026 12.1465 26 12.8978 26 13.8246V22.0879C26 22.8063 25.8115 23.5163 25.372 24.1034C24.6428 25.0775 23.332 26.562 21.7255 27.2107C19.232 28.2175 17.0022 28.3077 13.8889 27.2107Z"
        fill="white"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ContactUsIcon
