import { createReducer } from '@reduxjs/toolkit'
import { getWalletAddress, getWalletConnectState, getAccountInfo } from './action'

export interface WalletState {
  isConnected: boolean
  address: string
  accountInfo: Record<string, unknown> | null
}

const initialState: WalletState = {
  isConnected: false,
  address: '',
  accountInfo: {},
}

export default createReducer<WalletState>(initialState, (builder) =>
  builder
    .addCase(getWalletAddress, (state, { payload: { address } }) => {
      state.address = address
    })
    .addCase(getWalletConnectState, (state, { payload: { isConnected } }) => {
      state.isConnected = isConnected
    })
    .addCase(getAccountInfo, (state, { payload: { accountInfo } }) => {
      state.accountInfo = accountInfo
    })
)
