import React from 'react'
import { IconProps } from '../Icon'

const DisconnectIcon: React.FC<IconProps> = ({ color, size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M15.2001 8.49988H19.2001C19.6419 8.49988 20.0001 8.84809 20.0001 9.27766V14.7221C20.0001 15.1517 19.6419 15.4999 19.2001 15.4999H15.2001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.2 8.49988H4.8C4.35817 8.49988 4 8.84809 4 9.27766V14.7221C4 15.1517 4.35817 15.4999 4.8 15.4999H9.2"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 5V19" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.20007 12.0001H8.80007"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2001 12.0001H16.8001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DisconnectIcon
