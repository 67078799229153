import React from 'react'
import { IconProps } from '../Icon'

const MenuIcon: React.FC<IconProps> = ({ color, size = 32 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
      <path d="M48 0H0V48H48V0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M7.94971 11.9502H39.9497"
        stroke="#19D9F5"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.94971 23.9502H39.9497"
        stroke="#19D9F5"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9497 35.9502H39.9497"
        stroke="#19D9F5"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MenuIcon
