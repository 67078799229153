import React from 'react'
import styled, { keyframes } from 'styled-components'

export interface ModalProps {
  onDismiss?: () => void
  maxWidth?: number
  showBg?: boolean
  showBorder?: boolean
}

const Modal: React.FC<ModalProps> = ({ children, maxWidth, showBg, showBorder = false }) => {
  return (
    <StyledResponsiveWrapper maxWidth={maxWidth}>
      {!showBorder ? (
        <StyledModal showBg={showBg}>{children}</StyledModal>
      ) : (
        <StyledModalWithBorder>{children}</StyledModalWithBorder>
      )}
    </StyledResponsiveWrapper>
  )
}

const mobileKeyframes = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
`
interface StyledModalProps {
  maxWidth?: number
}

interface ModalWrapperProps {
  showBg?: boolean
}

const StyledResponsiveWrapper = styled.div<StyledModalProps>`
  display: flex;
  position: relative;
  max-width: ${(props) => props.maxWidth || 420}px;
  // border-radius: 4px;
  // border-image: linear-gradient(141.04deg, #e001e5 0%, #19d9f5 99.28%) 1;
  // clip-path: inset(0 round 4px);
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex: 1;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    animation: ${mobileKeyframes} 0.3s forwards ease-out;
  }
`

const StyledModal = styled.div<ModalWrapperProps>`
  padding: 0;
  background: ${(props) => (props.showBg ? 'linear-gradient(180deg, #2e102f 0%, #032551 81.77%)' : '#242735')};
  // border-radius: 4px;
  border: none;
  display: flex;
  flex-direction: column;
  width: 100%;
`
const StyledModalWithBorder = styled.div`
  padding: 0;
  background-color: '#242735';
  box-sizing: border-box;
  background-image: linear-gradient(#242735, #242735), linear-gradient(141.04deg, #e001e5 0%, #19d9f5 99.28%);
  padding: 1px;
  border-radius: 8px;
  background-clip: content-box, padding-box;
  // border-radius: 4px;
  border: none;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export default Modal
