import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Container from '../../components/Container'
import TagComp from './components/TagComp'
import ProductLink from './components/ProductLink'
import DappDesc from './components/DappDesc'
import Partners from './components/Partners'
import ContactUs from './components/ContactUs'
import HomeFooter from './components/HomeFooter'
import { ExChangeIcon, YieldHomeIcon, LoungeIcon } from '../../components/IconImage'
import PCHomeBanner from '../../assets/img/PCHomeBg.png'
import mobileHomeBanner from '../../assets/img/mobileHomeBg.png'
import mobileLogoImg from '../../assets/img/mobileLogoBg.png'
import PCLogoImg from '../../assets/img/PCLogoBg.png'
import ContentBg from '../../assets/img/contentBg.png'

import useMobile from '../../hooks/useMobile'
import { useTranslation } from 'react-i18next'

const Home: React.FC = () => {
  const { t } = useTranslation()
  const isMobile = useMobile()

  return (
    <HomeContent>
      <HomeTop>
        <HomeBanner />
        <Container size="lg">
          <MainWrapper>
            <LogoImg src={isMobile ? mobileLogoImg : PCLogoImg} />
            <DescText>An Innovative Group Liquidity and one-stop Yield Management Platform On Solana.</DescText>
            <LinkBtnWrapper>
              <StyledLink to="/yield">Launch App</StyledLink>
            </LinkBtnWrapper>
          </MainWrapper>
        </Container>
      </HomeTop>
      <HomeBottom>
        <ContentBanner />
        <HomeBottomWrapper>
          <Container size="lg">
            <StyledBlock>
              <TagComp tagText="What is SOH.COOL?" />
              <DappWrapper>
                <DappDesc text="SOH.COOL is the 1st Solana's group liquidity yield aggregator(LP composer),  and aims to provide a unique way for users to engage in liquidity provisioning and yields. By optimizing the liquidity composition algorithm and user experience, SOH.COOL wishes to make a simple, effective, and real profitable DeFi application for the masses on Solana." />
              </DappWrapper>
            </StyledBlock>
            <StyledBlock>
              <ProductWrapper>
                <TagComp tagText="SOH COOL Products" />
                <ProductContent>
                  <ProductLink
                    icon={<ExChangeIcon />}
                    btnText="Enter Yield"
                    link="/yield"
                    title="Yield"
                    desc="Earn yield through auto-compounding transaction fees and harvest from farm productions. Also earn SOH in the meanwhile."
                    disable={false}
                  />
                  <ProductLink
                    icon={<YieldHomeIcon />}
                    btnText="Enter NFT"
                    link="/NFT"
                    title="NFT"
                    desc="Augmented Functional NFT to boost user experience in various ways on SOL.COOL platform."
                    disable={true}
                  />
                  <ProductLink
                    icon={<LoungeIcon />}
                    btnText="Enter Pool"
                    link="/Pool"
                    title="Pool"
                    desc="A place for SOH fans to earn extra,  generate SOH from various partner assets, or , generate partner assets from SOH."
                    disable={true}
                  />
                </ProductContent>
              </ProductWrapper>
            </StyledBlock>
            <StyledBlock>
              <PartnerWrapper>
                <TagComp tagText="Our Partners" />
                <PartnerContent>
                  <Partners />
                </PartnerContent>
              </PartnerWrapper>
            </StyledBlock>
            <StyledBlock>
              <ContactWrapper>
                <ContactUs />
              </ContactWrapper>
            </StyledBlock>
          </Container>
          <HomeFooterWrapper>
            <HomeFooter />
          </HomeFooterWrapper>
        </HomeBottomWrapper>
      </HomeBottom>
    </HomeContent>
  )
}

const HomeContent = styled.div`
  min-height: inherit;
  background: linear-gradient(179.94deg, #221638 20.68%, #032551 83.86%);
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    background: linear-gradient(179.94deg, #221638 28.68%, #032551 83.86%);
  }
`

const HomeTop = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const HomeBottom = styled.div`
  display: flex;
  margin-top: 100px;
  position: relative;
`

const HomeFooterWrapper = styled.div`
  display: flex;
  margin-top: 44px;
`

const HomeBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledBlock = styled.div``

const HomeBanner = styled.div`
  width: 100%;
  height: 566px;
  background-image: url('${PCHomeBanner}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    height: 342px;
    background-image: url('${mobileHomeBanner}');
  }
`

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LogoImg = styled.img`
  width: 707px;
  height: 476px;
  object-fit: cover;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: 100%;
    height: 100%;
  }
`

const DescText = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: #fff5ff;
  max-width: 622px;
  text-align: center;
  margin-top: 24px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: 100%;
    font-size: 16px;
  }
`

const LinkBtnWrapper = styled.div`
  display: flex;
  background: linear-gradient(92.64deg, #f22bf7 0.2%, #4ed4ff 95.95%);
  border-radius: 8px;
  width: 230px;
  height: 62px;
  text-align: center;
  margin-top: 48px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: 120px;
    height: 40px;
    border-radius: 4px;
  }
`

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-weight: 800;
  font-size: 22px;
  white-space: pre;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &:hover,
  &.active {
    color: #fff;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    font-size: 16px;
  }
`

const ContentBanner = styled.div`
  width: 100%;
  height: 1078px;
  background-image: url('${ContentBg}');
  background-position: 20% 86%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    height: 730px;
    background-position: 40% 86%;
  }
`

const DappWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    margin-top: 48px;
  }
`

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 140px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    margin-top: 70px;
  }
`

const ProductContent = styled.div`
  display: flex;
  margin-top: 60px;
  & > :nth-child(2) {
    margin-left: 40px;
    margin-right: 40px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex-direction: column;
    margin-top: 48px;
    & > :nth-child(2) {
      margin: 40px 0 40px 0;
    }
  }
`

const PartnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 140px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    margin-top: 70px;
  }
`

const PartnerContent = styled.div`
  display: flex;
  margin-top: 60px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: 100%;
  }
`

const ContactWrapper = styled.div`
  margin-top: 120px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    margin-top: 60px;
  }
`

export default Home
