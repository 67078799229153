import React from 'react'
import { IconProps } from '../Icon'

const FarmArrowMobileIcon: React.FC<IconProps> = ({ style }) => {
  return (
    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" style={style}>
      <path
        d="M6.58578 8.58579C7.36683 9.36683 8.63316 9.36684 9.41421 8.58579L15.4393 2.56066C16.3843 1.61571 15.715 0 14.3787 0H1.62132C0.284964 0 -0.384287 1.61571 0.56066 2.56066L6.58578 8.58579Z"
        fill="#FEEAFF"
      />
    </svg>
  )
}

export default FarmArrowMobileIcon
