import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import useModal from '../../../hooks/useModal'
import useMobile from '../../../hooks/useMobile'
import AccountDetails from 'components/AccountDetails'
import walletIcon from '../../../assets/img/wallet.png'
import { Button } from 'antd'
import { formatAddress } from '../../../utils/formatAddress'

interface AccountProps {
  address: string
}

const AccountButton: React.FC<AccountProps> = ({ address }) => {
  const isMobile = useMobile()

  const [onAccountDetailsProviderModal] = useModal(<AccountDetails />, 'provider')

  const handleUnlockClick = useCallback(() => {
    onAccountDetailsProviderModal()
  }, [onAccountDetailsProviderModal])

  return (
    <StyledAccountButton isMobile={isMobile}>
      <Button shape="round" size="large" className="account" onClick={handleUnlockClick}>
        <StyledWalletIcon src={walletIcon} />
        {formatAddress(address)}
      </Button>
    </StyledAccountButton>
  )
}

const StyledWalletIcon = styled.img`
  width: 19.2px;
  height: 19.2px;
  object-fit: contain;
  margin-right: 14px;
`

const StyledAccountButton = styled.div<{ isMobile: boolean }>`
  position: relative;
  height: 40px;
  border: 2px solid;
  border-image: linear-gradient(94.48deg, #e001e5 5.16%, #19d9f5 91.31%) 1;
  clip-path: inset(0 round 4px);
  display: flex;
  align-items: center;

  .ant-btn-round.ant-btn-lg {
    height: 40px;
    padding: 5px 18px;
    border: none;
    border-radius: 4px;
    color: #19d9f5;
    background: transparent;
    font-weight: 600;
    line-height: 30px;
    font-size: 14px;
    cursor: pointer;
    &.account {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
  }
`

export default AccountButton
