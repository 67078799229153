import { notification } from 'antd'
import {
  NoticeCloseIcon,
  SuccessNoticeIcon,
  InfoNoticeIcon,
  ErrorNoticeIcon,
  WarningNoticeIcon,
} from '../../components/IconImage'

export const SuccessNotice = (message?: string, desc?: string) => {
  notification.success({
    closeIcon: <NoticeCloseIcon />,
    icon: <SuccessNoticeIcon />,
    message: message,
    description: desc,
    duration: 2,
  })
}

export const InfoNotice = (message?: string, desc?: string) => {
  notification.info({
    closeIcon: <NoticeCloseIcon />,
    icon: <InfoNoticeIcon />,
    message: message,
    description: desc,
    duration: 2,
  })
}

export const ErrorNotice = (message?: string, desc?: string) => {
  notification.error({
    closeIcon: <NoticeCloseIcon />,
    icon: <ErrorNoticeIcon />,
    message: message,
    description: desc,
    duration: 2,
  })
}

export const WarningNotice = (message?: string, desc?: string) => {
  notification.warning({
    closeIcon: <NoticeCloseIcon />,
    icon: <WarningNoticeIcon />,
    message: message,
    description: desc,
    duration: 2,
  })
}
