import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import useMobile from '../../../hooks/useMobile'
import { ArrowIcon, CheckIcon } from '../../IconImage'

const Transaction: React.FC = () => {
  const isMobile = useMobile()

  return (
    <StyledWrapper>
      <StyledTop>
        <StyledTitle>Recent Transactions</StyledTitle>
        <StyledClear>Clear all</StyledClear>
      </StyledTop>
      <StyledList>
        <StyledListWrapper>
          <StyledTextWrapper>
            <StyledText>Approve USDT</StyledText>
            <ArrowIcon size={12} color={'#b9b3b9'} />
          </StyledTextWrapper>
          <StyledStatus>
            <CheckIcon size={24} color={'#3dd598'} />
          </StyledStatus>
        </StyledListWrapper>
        <StyledListWrapper>
          <StyledTextWrapper>
            <StyledText>Swap</StyledText>
            <ArrowIcon size={12} color={'#b9b3b9'} />
          </StyledTextWrapper>
          <StyledStatus>
            <CheckIcon size={24} color={'#3dd598'} />
          </StyledStatus>
        </StyledListWrapper>
      </StyledList>
      <StyledNoData>No results found</StyledNoData>
    </StyledWrapper>
  )
}

const StyledNoData = styled.span`
  color: rgba(185, 179, 185, 0.8);
  font-size: 14px;
`

const StyledStatus = styled.div``

const StyledText = styled.span`
  font-size: 14px;
  color: rgba(185, 179, 185, 0.8);
  margin-right: 10px;
`

const StyledTextWrapper = styled.div`
  cursor: pointer;
`

const StyledListWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
`

const StyledList = styled.div`
  margin-top: 16px;
  & > div:last-child {
    margin-bottom: 0;
  }
`

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #fff5ff;
`

const StyledClear = styled.div`
  font-size: 12px;
  color: #fc5a5a;
  cursor: pointer;
`

const StyledTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 34px;
`

export default Transaction
