import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import NotAccountButton from './components/NotConnectButton'
import AccountButton from './components/AccountButton'
import TopMenu from './components/TopMenu'
import Logo from '../../assets/img/logo.png'
import LogoText from '../../assets/img/logoText.png'
import useMobile from '../../hooks/useMobile'
import { useTranslation } from 'react-i18next'
import LangSwitch from './components/LangSwitch'
import Notice from './components/Notice'
import Spacer from '../Spacer'
import { useWalletState } from '../../store/wallet/hooks'
import useModal from '../../hooks/useModal'
import SelectWalletModal from '../SelectWalletModal'

interface TopBarProps {
  children?: React.ReactNode
}

const TopBar: React.FC<TopBarProps> = ({ children }) => {
  const isMobile = useMobile()
  const { t } = useTranslation()
  const { address, isConnected } = useWalletState()
  const [onPresentWalletProviderModal] = useModal(<SelectWalletModal />, 'provider')
  useEffect(() => {
    if (!isConnected && !address) {
      onPresentWalletProviderModal()
    }
  }, [isConnected])

  return (
    <StyledTopBar>
      {isMobile ? (
        <StyledTopBarLightInner>
          <StyledLogoWrapper>
            <StyledLogo src={Logo} />
          </StyledLogoWrapper>
          <StyledActionsWrapper>
            {isConnected ? <AccountButton address={address} /> : <NotAccountButton />}
            <Spacer size="sm" />
            {/*<Notice />*/}
            <Spacer size="sm" />
            <TopMenu />
          </StyledActionsWrapper>
        </StyledTopBarLightInner>
      ) : (
        <StyledTopBarInnerWrapper>
          <StyledLogoWrapper>
            <StyledLogo src={LogoText} />
          </StyledLogoWrapper>
          <TopMenu />
          <StyledActionsWrapper>
            {/*<Notice />*/}
            <Spacer size="md" />
            <LangSwitch />
            <Spacer size="md" />
            {isConnected ? <AccountButton address={address} /> : <NotAccountButton />}
          </StyledActionsWrapper>
          {children}
        </StyledTopBarInnerWrapper>
      )}
    </StyledTopBar>
  )
}

const StyledActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledLogo = styled.img`
  width: 190px;
  height: 32px;
  object-fit: contain;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: 36px;
    height: 36px;
  }
`

const StyledLogoWrapper = styled.div`
  width: auto;
  display: flex;
`

const StyledTopBar = styled.div`
  position: relative;
  background: #221638;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
  padding: 0 32px;
  z-index: 100;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    padding: 0 20px;
  }
`

const StyledTopBarLightInner = styled.div`
  display: flex;
  align-items: center;
  height: ${(props) => props.theme.topBarSize}px;
  line-height: ${(props) => props.theme.topBarSize}px;
  justify-content: space-between;
  max-width: unset;
  width: 100%;
`

const StyledTopBarInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${(props) => props.theme.topBarSize}px;
  line-height: ${(props) => props.theme.topBarSize}px;
  justify-content: space-between;
  max-width: unset;
  width: 100%;
`

export default TopBar
