import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import walletIcon from '../../../assets/img/wallet.png'
import { useTranslation } from 'react-i18next'
import useMobile from '../../../hooks/useMobile'
import useModal from '../../../hooks/useModal'
import SelectWalletModal from '../../SelectWalletModal'

const NotAccountButton: React.FC = () => {
  const { t } = useTranslation()
  const isMobile = useMobile()

  const [onPresentWalletProviderModal] = useModal(<SelectWalletModal />, 'provider')

  const handleUnlockClick = useCallback(() => {
    onPresentWalletProviderModal()
  }, [onPresentWalletProviderModal])

  return (
    <StyledAccountButton isMobile={isMobile}>
      <Button shape="round" size="large" className="account" onClick={handleUnlockClick}>
        <StyledWalletIcon src={walletIcon} />
        Connect Wallet
      </Button>
    </StyledAccountButton>
  )
}

const StyledWalletIcon = styled.img`
  width: 19.2px;
  height: 19.2px;
  object-fit: contain;
  margin-right: 14px;
`

const StyledAccountButton = styled.div<{ isMobile: boolean }>`
  position: relative;
  height: 40px;
  border: 2px solid;
  border-image: linear-gradient(94.48deg, #e001e5 5.16%, #19d9f5 91.31%) 1;
  clip-path: inset(0 round 4px);
  display: flex;
  align-items: center;

  .ant-btn-round.ant-btn-lg {
    height: 40px;
    padding: 5px 18px;
    border: none;
    border-radius: 4px;
    color: #19d9f5;
    background: transparent;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    &.account {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
  }
`

export default NotAccountButton
