import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Menu, Popover } from 'antd'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { LangIcon } from '../../IconImage'

const LANGS = [
  { lang: 'en', name: 'English' },
  { lang: 'zh', name: '简体中文' },
]

const LangSwitch: React.FC = (props) => {
  const { t, i18n } = useTranslation()
  const [visibleLangOpt, setVisibleLangOpt] = useState(false)
  const [lang, setLang] = useState(i18n?.languages[0] || 'en')

  const node = useRef<HTMLDivElement>()
  useOnClickOutside(node, setVisibleLangOpt ? () => setVisibleLangOpt(false) : undefined)

  const hangdleLangChange = useCallback(
    ({ key: lang }) => {
      i18n.changeLanguage(lang)
      document.documentElement.lang = lang
      setVisibleLangOpt(false)
      setLang(lang)
    },
    [i18n]
  )

  useEffect(() => {
    document.documentElement.lang = i18n.languages[0] // i18n.language
    setLang(i18n.languages[0])
  }, [])

  const menu = useMemo(() => {
    return (
      <div ref={node as any}>
        <Menu onClick={hangdleLangChange} className="lang-box">
          {LANGS.length > 0 ? (
            LANGS.map((language, i) => <Menu.Item key={language.lang}>{language.name}</Menu.Item>)
          ) : (
            <>Loading...</>
          )}
        </Menu>
      </div>
    )
  }, [])

  return (
    <>
      <Popover
        content={menu}
        trigger="click"
        placement="bottomLeft"
        overlayClassName="lang-popover"
        visible={visibleLangOpt}
      >
        <DropdownIconWrapper
          className="ant-dropdown-link"
          onClick={(e) => {
            setVisibleLangOpt(!visibleLangOpt)
            e.preventDefault()
          }}
        >
          <LangBox>
            <LangIcon size={24} />
            {lang.toUpperCase()}
          </LangBox>
        </DropdownIconWrapper>
      </Popover>
    </>
  )
}

const DropdownIconWrapper = styled.a`
  display: flex;
  &.ant-dropdown-link {
    color: #fff;
  }
`
const LangBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #19d9f5;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  & > svg {
    margin-right: 8px;
  }
`
export default LangSwitch
