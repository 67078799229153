import React from 'react'
import styled from 'styled-components'

import AXIA8Img from '../../../assets/img/AXIA8.png'
import KRYPITAL from '../../../assets/img/KRYPITAL.png'

import useMobile from '../../../hooks/useMobile'
import { useTranslation } from 'react-i18next'

const Partners: React.FC = () => {
  const { t } = useTranslation()
  const isMobile = useMobile()

  return (
    <PartnerContainer>
      <Wrapper>
        <PartnerImg src={AXIA8Img} />
      </Wrapper>
      <Wrapper>
        <PartnerImg src={KRYPITAL} />
      </Wrapper>
    </PartnerContainer>
  )
}

const PartnerContainer = styled.div`
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: 100%;
    justify-content: space-around;
  }
`

const Wrapper = styled.div`
  width: 276px;
  height: 110px;
  text-align: center;
  line-height: 110px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: inset 0px 1px 10px rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(100px);
  border-radius: 20px;
  margin-left: 70px;
  margin-right: 70px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: 144px;
    height: 55px;
    line-height: 55px;
    margin-left: 0px;
    margin-right: 0px;
  }
`

const PartnerImg = styled.img`
  width: auto;
  height: auto;
  object-fit: cover;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: 70px;
    height: 28px;
  }
`

export default Partners
