import React from 'react'
import { IconProps } from '../Icon'

const TelegramIcon: React.FC<IconProps> = ({ color, size = 24 }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 48 48" fill="none">
      <rect width="48" height="48" rx="8" fill="url(#paint0_linear_2998_10155)" />
      <path
        d="M13.65 22.3354C20.0925 18.9479 24.3888 16.7144 26.5389 15.6352C32.6761 12.554 33.9511 12.0196 34.7834 12.0004C35.0888 11.9924 35.3886 12.1008 35.6401 12.3102C35.8162 12.495 35.9284 12.7529 35.9545 13.0327C35.9986 13.3782 36.0107 13.7285 35.9906 14.0771C35.6589 18.295 34.218 28.5255 33.4867 33.2535C33.1766 35.2519 32.5665 35.9219 31.9765 35.9881C30.6929 36.1308 29.7178 34.9646 28.4745 33.981C26.5302 32.4422 25.4312 31.4847 23.5431 29.9772C21.3608 28.2364 22.7758 27.2878 24.0191 25.7298C24.3451 25.3225 29.9991 19.1148 30.1087 18.5508C30.1232 18.468 30.1214 18.3822 30.1037 18.3003C30.0859 18.2184 30.0525 18.1427 30.0063 18.0791C29.9389 18.0275 29.8624 17.9958 29.783 17.9864C29.7036 17.9771 29.6235 17.9902 29.5491 18.0251C29.3568 18.0785 26.2494 20.5573 20.2271 25.4616C19.5397 26.1102 18.7017 26.4838 17.83 26.5305C16.6579 26.3596 15.5054 26.0303 14.3943 25.5488C13.0096 25.0056 11.9106 24.7184 12.0057 23.7976C12.0567 23.3241 12.6048 22.8367 13.65 22.3354Z"
        fill="white"
      />
      <rect
        x="0.25"
        y="0.25"
        width="47.5"
        height="47.5"
        rx="7.75"
        stroke="url(#paint1_linear_2998_10155)"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient id="paint0_linear_2998_10155" x1="4" y1="3" x2="48" y2="48" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.13" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear_2998_10155" x1="2.5" y1="4" x2="46" y2="48" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A7A8A8" />
          <stop offset="0.536458" stopColor="white" stopOpacity="0.18" />
          <stop offset="1" stopColor="white" stopOpacity="0.9" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default TelegramIcon
