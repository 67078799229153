import React from 'react'
import { IconProps } from '../Icon'

const YieldMenuIcon: React.FC<IconProps> = ({ color, size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 40 40" fill="none">
      <path d="M40 0H0V40H40V0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M3.33301 30V33.3333C3.33301 35.1742 4.82539 36.6667 6.66634 36.6667H33.333C35.1739 36.6667 36.6663 35.1742 36.6663 33.3333V30"
        stroke="#D8CED8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M32 4H8V28H32V4Z" fill="white" fillOpacity="0.01" />
      <path
        d="M20 26C25.5228 26 30 21.5229 30 16C30 10.4771 25.5228 6 20 6C14.4771 6 10 10.4771 10 16C10 21.5229 14.4771 26 20 26Z"
        stroke="#D8CED8"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
      <g clip-path="url(#clip0_3117_7485)">
        <path d="M25 11H15V21H25V11Z" fill="white" fillOpacity="0.01" />
        <path d="M20 11.417V20.5837" stroke="#D8CED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M22.292 12.25C22.292 12.25 20.2025 12.25 19.167 12.25C18.1315 12.25 17.292 13.0895 17.292 14.125C17.292 15.1605 18.1315 16 19.167 16"
          stroke="#D8CED8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.708 19.75C17.708 19.75 19.7975 19.75 20.833 19.75C21.8685 19.75 22.708 18.9105 22.708 17.875C22.708 16.8395 21.8685 16 20.833 16H19.1663"
          stroke="#D8CED8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3117_7485">
          <rect width="10" height="10" fill="white" transform="translate(15 11)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default YieldMenuIcon
