// lib
import React, { useState } from 'react'
import styled from 'styled-components'
import YieldExpandedPool from './YieldExpandPool'
import YieldBtn from './YieldBtn'

// hooks
import { useTranslation } from 'react-i18next'
import useMobile from '../../../hooks/useMobile'

interface ExpandRow {
  isExpanding: boolean
  pool: any
}

const YieldExpandedMobile: React.FC<ExpandRow> = ({ isExpanding }) => {
  const { t } = useTranslation()
  const [tabName, setTabName] = useState<string>('pool')
  const [coinName, setCoinName] = useState<string>('JF')

  const handleTabChange = (name: string) => {
    setTabName(name)
  }

  const handleCoinChange = (name: string) => {
    setCoinName(name)
  }

  return (
    <StyleContent className={isExpanding ? 'expand-mobile' : ''}>
      <StyleWrapper>
        <StyledDataList>
          <StyledDataGroup className={'flex-top'}>
            <StyledDName>Paired</StyledDName>
            <StyledDNum>
              <StyledDNText>JF- 0.0000</StyledDNText>
              <StyledDNText>USDT- 0.0000</StyledDNText>
            </StyledDNum>
          </StyledDataGroup>
          <StyledDataGroup className={'flex-top'}>
            <StyledDName>In Queue</StyledDName>
            <StyledDNum>
              <StyledDNText>JF- 0.0000</StyledDNText>
              <StyledDNText>USDT- 0.0000</StyledDNText>
            </StyledDNum>
          </StyledDataGroup>
          <StyledDataGroup className={'flex-top'}>
            <StyledDName>Depositable</StyledDName>
            <StyledDNum>
              <StyledDNText>JF- 0.0000</StyledDNText>
              <StyledDNText>USDT- 0.0000</StyledDNText>
            </StyledDNum>
          </StyledDataGroup>
          <StyledDataGroup className={'flex-group'}>
            <StyledDName>My Assets</StyledDName>
            <StyledDNum>
              <span className={'spanData'}>$ 68.83</span>
            </StyledDNum>
          </StyledDataGroup>
        </StyledDataList>
        <StyledTabGroup>
          <StyledTabBtn className={tabName === 'pool' ? 'tabActive' : ''} onClick={() => handleTabChange('pool')}>
            Pool Stats
          </StyledTabBtn>
          <StyledTabBtn className={tabName === 'my' ? 'tabActive' : ''} onClick={() => handleTabChange('my')}>
            My Details
          </StyledTabBtn>
        </StyledTabGroup>
        <StyledCoinTab>
          <CoinTabBtn onClick={() => handleCoinChange('JF')}>
            <CoinTabName>JF</CoinTabName>
            <CoinTabLine className={coinName === 'JF' ? 'coinActive' : ''} />
          </CoinTabBtn>
          <CoinTabBtn onClick={() => handleCoinChange('USDT')}>
            <CoinTabName>USDT</CoinTabName>
            <CoinTabLine className={coinName === 'USDT' ? 'coinActive' : ''} />
          </CoinTabBtn>
        </StyledCoinTab>
        {/*<StyledTabWarp>{tabName === 'pool' ? <YieldExpandedPool pool={} /> : <YieldExpandedPool />}</StyledTabWarp>*/}
      </StyleWrapper>
    </StyleContent>
  )
}

const StyleContent = styled.div`
  height: 0px;
  overflow: hidden;
  transition: all 0.3s;
`

const StyleWrapper = styled.div`
  padding: 16px;
  background: #20173a;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  .tabActive {
    background-color: rgba(255, 255, 255, 0.3);
    color: #fff5ff;
  }
`

const StyledDataList = styled.div`
  margin-bottom: 16px;
`

const StyledDataGroup = styled.div`
  margin-bottom: 8px;
`

const StyledDName = styled.div``

const StyledDNum = styled.div`
  color: #fff5ff;
  text-align: right;
  .spanData {
    color: #97eacc;
  }
`

const StyledDNText = styled.div``

const StyledTabGroup = styled.div`
  display: flex;
  align-items: center;
  margin: 32px 0 8px;
  color: #b9b3b9;
`

const StyledTabBtn = styled.div`
  padding: 4px 16px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  transition: all 0.3s;
  cursor: pointer;
  &:first-of-type {
    border-right: none;
    border-radius: 4px 0 0 4px;
  }
  &:last-of-type {
    border-radius: 0 4px 4px 0;
  }
`

const StyledTabWarp = styled.div``

const StyledCoinTab = styled.div`
  margin: 16px 0;
  display: flex;
  border-bottom: 1px solid rgba(254, 234, 255, 0.2);
`

const CoinTabBtn = styled.div`
  margin: 0 10px;
  text-align: center;
  .coinActive {
    background: linear-gradient(94.8deg, #e000e4 4.74%, #14def5 100%);
  }
`

const CoinTabName = styled.div`
  padding: 0 5px;
  color: #fff5ff;
`

const CoinTabLine = styled.div`
  margin-top: 8px;
  width: 100%;
  height: 2px;
  background: transparent;
`

export default YieldExpandedMobile
