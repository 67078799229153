import React from 'react'
import styled from 'styled-components'
import useCopyToClipboard from '../../../hooks/useCopyToClipboard'

import { LinkStyledButton } from '../../../theme/components'
import { CopyIcon } from '../../IconImage'

const CopyButton = styled(LinkStyledButton)`
  color: #b9b3b9;
  font-weight: normal;
  font-size: 14px;
  margin-left: 12px;
  flex-shrink: 0;
  display: flex;
  text-decoration: none;
  :hover,
  :active,
  :focus {
    text-decoration: none;
  }
`
const TransactionStatusText = styled.span`
  margin-left: 12px;
  font-size: 14px;
  align-items: center;
  &.NoMargin {
    margin-left: 0;
  }
`

const StatusText = styled.span`
  margin-left: 12px;
`

export default function CopyHelper(props: { toCopy: string; isShowCopied?: boolean; children?: React.ReactNode }) {
  const [isCopied, setCopied] = useCopyToClipboard()
  const isShowCopied = props.isShowCopied !== undefined ? props.isShowCopied : true

  return (
    <CopyButton onClick={() => setCopied(props.toCopy ?? '')}>
      {isCopied ? (
        <TransactionStatusText>
          <TransactionStatusText className={isShowCopied ? '' : 'NoMargin'}>
            <CopyIcon size={20} color={'#b9b3b9'} />
            <StatusText>{isShowCopied ? 'Copied' : ''}</StatusText>
          </TransactionStatusText>
        </TransactionStatusText>
      ) : (
        <TransactionStatusText></TransactionStatusText>
      )}
      {isCopied ? '' : props.children}
    </CopyButton>
  )
}
