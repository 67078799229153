import React from 'react'
import { IconProps } from '../Icon'

const ShareIcon: React.FC<IconProps> = ({ color, size = 24 }) => {
  return (
    <svg width={size} height={size} style={{ verticalAlign: 'middle' }} viewBox="0 0 24 24" fill="none">
      <path d="M13.5 5.25H18.75V10.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M18.75 14.0526V17.625C18.75 18.2463 18.2463 18.75 17.625 18.75H6.375C5.75368 18.75 5.25 18.2463 5.25 17.625V6.375C5.25 5.75368 5.75368 5.25 6.375 5.25H9.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.675 11.325L18.4125 5.58746"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ShareIcon
