import { createReducer } from '@reduxjs/toolkit'
import { refreshCount } from './action'

export interface YieldState {
  count: number
}

const initialState: YieldState = {
  count: 1,
}

export default createReducer<YieldState>(initialState, (builder) =>
  builder.addCase(refreshCount, (state) => {
    state.count += 1
    console.log('state.count', state.count)
  })
)
