import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import Modal, { ModalProps } from '../../../components/Modal'
import ModalContent from '../../../components/ModalContent'
import AmountInputContent from '../../../components/AmountInputContent'
import ModalTitle from '../../../components/ModalTitle'
import { useTranslation } from 'react-i18next'
import useMobile from '../../../hooks/useMobile'
import { CloseIcon, FarmArrowIcon } from '../../../components/IconImage'
import useWithdraw from '../../../hooks/useWithdraw'
// import { Direction } from '../../../client-js/types'
import { Direction } from '@soh-cool/client-sdk/dist/types'
import { SuccessNotice } from '../../../components/Notice'
import { Button } from 'antd'

interface DepositProp extends ModalProps {
  poolInfo: any
}

const YieldWithdrawModal: React.FC<DepositProp> = ({ poolInfo, onDismiss, maxWidth = 560 }) => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const [withDAmount, setWithDAmount] = useState<string>('')
  const [disabledBtn, setDisabledBtn] = useState<boolean>(false)
  const percentList = ['25%', '50%', '75%', '100%']
  const [typeName, setTypeName] = useState<string>('Withdraw')
  const [pool, setPool] = useState<any>()
  const { onWithdraw: handleWithdraw } = useWithdraw()

  const handleAmountVal = useCallback((val) => {
    console.log('val', val)
    setWithDAmount(val)
  }, [])

  const handleWithdrawClick = async () => {
    // console.log(withDAmount, 'amountIn')
    setDisabledBtn(true)
    if (poolInfo) {
      const aaa = await handleWithdraw(
        poolInfo.tokenA.mintAddress,
        poolInfo.tokenB.mintAddress,
        poolInfo,
        withDAmount,
        Direction.AToB
      )
      console.log(9900, aaa)
      setDisabledBtn(false)
      onDismiss!()
      SuccessNotice('Success', 'Withdraw Success')
    }
  }
  useMemo(() => {
    console.log('poolInfo', poolInfo)
    setPool(poolInfo)
  }, [poolInfo])

  return (
    <StakeModalWrapper
      isMobile={isMobile}
      className={isMobile ? 'center-body' : ''}
      style={isMobile ? { width: '100%' } : { minWidth: '420px' }}
    >
      <Modal maxWidth={maxWidth}>
        <ModalTitle>
          <StyledTitle>Withdraw</StyledTitle>
          <StyledCloseIconWrapper onClick={() => onDismiss!()}>
            <CloseIcon color="#B0B1B8" size={24} />
          </StyledCloseIconWrapper>
        </ModalTitle>
        <ModalContent>
          <StyledTabGroup isMobile={isMobile}>
            <StyledTabBtn isMobile={isMobile}>
              <span style={{ verticalAlign: 'middle' }}>SOL </span>
              <FarmArrowIcon size={16} style={{ verticalAlign: 'middle' }} />
            </StyledTabBtn>
            <StyledTabBtn isMobile={isMobile}>
              <span style={{ verticalAlign: 'middle' }}>Doctrine </span>
              <FarmArrowIcon size={16} style={{ verticalAlign: 'middle' }} />
            </StyledTabBtn>
          </StyledTabGroup>
          <StyledTapTips isMobile={isMobile}>
            I can accept counterparty tokens for lesser impermanent Loss
          </StyledTapTips>
          <AmountInputContent
            getAmountVal={handleAmountVal}
            pool={poolInfo}
            percentList={percentList}
            balance={pool?.userMaxWithdraw ?? 0}
            type={'withdraw'}
          />
          <StyledButtonWarp isMobile={isMobile}>
            {/*<BusinessButton type={typeName} disabled={disabledBtn} onClick={handleWithdrawClick} />*/}
            <BtnWarp>
              {disabledBtn ? (
                <Button className={'btn'} disabled={disabledBtn}>
                  <Dots>{typeName}</Dots>
                </Button>
              ) : (
                <Button className={'btn'} onClick={handleWithdrawClick} disabled={disabledBtn}>
                  {typeName}
                </Button>
              )}
            </BtnWarp>
          </StyledButtonWarp>
        </ModalContent>
      </Modal>
    </StakeModalWrapper>
  )
}

interface MobileProps {
  isMobile: boolean
}

const StakeModalWrapper = styled.div<MobileProps>`
  &.center-body > div {
    justify-content: center;
    position: relative;
    max-height: initial;
    margin: ${(props) => (props.isMobile ? '0 20px' : '0 auto')};
  }
`

const StyledCloseIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const StyledTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #fff5ff;
`

const StyledButtonWarp = styled.div<MobileProps>`
  margin: 32px auto 0;
  padding-bottom: 8px;
  width: ${(props) => (props.isMobile ? '100%' : '70%')};
  text-align: center;
`

const StyledTabGroup = styled.div<MobileProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.isMobile ? '70%' : '50%')};
  margin: 0 auto;
  .active {
    color: #fff5ff;
    background-color: rgba(255, 255, 255, 0.3);
  }
`

const StyledTabBtn = styled.div<MobileProps>`
  width: 50%;
  padding: 4px 0;
  color: #b9b3b9;
  font-size: ${(props) => (props.isMobile ? '12px' : '14px')};
  font-weight: 600;
  line-height: ${(props) => (props.isMobile ? '22px' : '30px')};
  text-align: center;
  cursor: pointer;
  background-color: rgba(31, 19, 31, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.6);
  &:first-of-type {
    border-right: none;
    border-radius: 4px 0 0 4px;
  }
  &:last-of-type {
    border-radius: 0 4px 4px 0;
  }
`

const StyledTapTips = styled.div<MobileProps>`
  width: ${(props) => (props.isMobile ? '70%' : '50%')};
  margin: 8px auto 24px;
  font-size: 12px;
  line-height: 20px;
  color: #b9b3b9;
`

const BtnWarp = styled.div`
  .btn {
    width: 100%;
    height: 40px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    background-color: transparent;
    background-image: linear-gradient(94.19deg, #e000e4 4.18%, #14def5 88.14%);
    border-radius: 4px;
    border: none;
  }
  .ant-btn[disabled] {
    color: rgba(100, 90, 105, 1);
    background: transparent;
    border: 1px solid #645a69;
  }
`

const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

export default YieldWithdrawModal
