import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Modal, { ModalProps } from '../Modal'
import ModalContent from '../ModalContent'
import ModalTitle from '../ModalTitle'
import { useTranslation } from 'react-i18next'
import useMobile from '../../hooks/useMobile'
import { CloseIcon, CopyIcon, ShareIcon, DisconnectIcon, SwitchICon } from '../IconImage'
import Transaction from './components/Transaction'
import Copy from './components/Copy'
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletActionHandlers, useWalletState } from '../../store/wallet/hooks'
import { formatAddress } from '../../utils/formatAddress'
import { getSolscanLink } from '../../utils/utils'

const AccountDetails: React.FC<ModalProps> = ({ onDismiss, maxWidth = 628 }) => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const { wallet, disconnect, connected } = useWallet()
  const { walletConnectState, walletAddress } = useWalletActionHandlers()
  const { address } = useWalletState()
  const handleDisconnect = () => {
    if (connected) {
      disconnect().catch((err) => {
        console.log(err)
      })
    }
  }

  const openInNewWindow = (href: string) => {
    window.open(href, '_blank')
  }

  useMemo(() => {
    if (!connected) {
      walletAddress('')
      walletConnectState(false)
      onDismiss!()
    }
  }, [disconnect, connected])
  return (
    <SelectWalletModalWrapper
      className={isMobile ? 'center-body' : ''}
      style={isMobile ? { width: '100%' } : { minWidth: '628px' }}
    >
      <Modal showBorder={true} maxWidth={maxWidth}>
        <ModalTitle>
          <StyledTitle>Account</StyledTitle>
          <StyledCloseIconWrapper onClick={() => onDismiss!()}>
            <CloseIcon color="#B0B1B8" size={24} />
          </StyledCloseIconWrapper>
        </ModalTitle>
        <ModalContent>
          <StyledContentTitle>Connect with Phantom</StyledContentTitle>
          <StyledAccountButtonArea>{formatAddress(address)}</StyledAccountButtonArea>
          <StyledOperateArea>
            <StyledOperateWrapper>
              <Copy toCopy={address}>
                <CopyIcon size={20} color={'#b9b3b9'} />
                <StyledOperateText>Copy Address</StyledOperateText>
              </Copy>
            </StyledOperateWrapper>
            <StyledOperateWrapper onClick={() => openInNewWindow(getSolscanLink(address, 'address'))}>
              <ShareIcon size={20} color={'#b9b3b9'} />
              <StyledOperateText>View On Solscan</StyledOperateText>
            </StyledOperateWrapper>
            <StyledOperateWrapper>
              <SwitchICon size={20} color={'#b9b3b9'} />
              <StyledOperateText>Switch</StyledOperateText>
            </StyledOperateWrapper>
            {!isMobile ? (
              <StyledOperateWrapper onClick={handleDisconnect}>
                <DisconnectIcon size={20} color={'#b9b3b9'} />
                <StyledOperateText>Disconnect</StyledOperateText>
              </StyledOperateWrapper>
            ) : null}
          </StyledOperateArea>
          {isMobile ? (
            <StyledOperateWrapper onClick={handleDisconnect}>
              <StyledDisconnectButton>Disconnect Wallet</StyledDisconnectButton>
            </StyledOperateWrapper>
          ) : null}
          <Transaction />
        </ModalContent>
      </Modal>
    </SelectWalletModalWrapper>
  )
}

const StyledOperateText = styled.span`
  color: #b9b3b9;
  font-weight: normal;
  font-size: 14px;
  margin-left: 12px;
  white-space: pre;
`

const StyledOperateWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    justify-content: center;
  }
`

const StyledDisconnectButton = styled.div`
  height: 40px;
  border: 2px solid;
  border-image: linear-gradient(94.48deg, #e001e5 5.16%, #19d9f5 91.31%) 1;
  clip-path: inset(0 round 4px);
  display: flex;
  align-items: center;
  color: #19d9f5;
  padding: 16px 56px;
  margin-top: 40px;
`

const StyledOperateArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`

const StyledAccountButtonArea = styled.div`
  background: #101322;
  border-radius: 8px;
  font-size: 24px;
  color: #fff5ff;
  padding: 24px;
  margin-top: 16px;
  line-height: 32px;
  font-weight: 600;
`

const StyledContentTitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  color: #b9b3b9;
`

const StyledCloseIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const StyledTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #fff5ff;
`

const SelectWalletModalWrapper = styled.div`
  &.center-body > div {
    justify-content: center;
    position: relative;
    max-height: initial;
    margin: 0 auto;
  }
`

export default AccountDetails
