import React from 'react'
import styled from 'styled-components'
import Container from '../../components/Container'
import useMobile from '../../hooks/useMobile'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../components/PageTitle'
import FiltersComp from '../../components/FiltersComp'
import YieldBg from '../../assets/img/yieldBg.png'
import YieldList from './components/YieldList'
import YieldSortList from './components/YieldSortList'

const Yield: React.FC = () => {
  const { t } = useTranslation()
  const isMobile = useMobile()

  return (
    <StyledYieldWarp>
      <Container size="lg" style={{ paddingBottom: isMobile ? '80px' : '' }}>
        <StyledYieldBgIcon isMobile={isMobile}>
          <YieldBgIcon src={YieldBg} alt={''} />
        </StyledYieldBgIcon>
        <PageTitle title={'Yield'} desp={'Earn yield through fees and yield farms'} tips={'(LP Composition)'} />
        <FiltersComp />
        {/*{!isMobile && <YieldSortList />}*/}
        <YieldList />
      </Container>
    </StyledYieldWarp>
  )
}

interface MobileProp {
  isMobile: boolean
}

const StyledYieldWarp = styled.div`
  position: relative;
  padding-bottom: 100px;
`

const StyledYieldBgIcon = styled.div<MobileProp>`
  position: absolute;
  top: 0;
  right: ${(prop) => (prop.isMobile ? '20px' : '125px')};
  z-index: 0;
  width: ${(prop) => (prop.isMobile ? '65%' : '50%')};
`

const YieldBgIcon = styled.img`
  width: 100%;
`

export default Yield
