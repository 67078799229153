import React from 'react'
import { IconProps } from '../Icon'

const LangIcon: React.FC<IconProps> = ({ color, size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 22C17.5227 22 21.9998 17.5229 21.9998 12C21.9998 6.47715 17.5227 2 11.9998 2C6.47697 2 1.99982 6.47715 1.99982 12C1.99982 17.5229 6.47697 22 11.9998 22Z"
        stroke="#19D9F5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1.99982 12H21.9998" stroke="#19D9F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 22C14.209 22 15.9998 17.5229 15.9998 12C15.9998 6.47715 14.209 2 11.9998 2C9.79067 2 7.99982 6.47715 7.99982 12C7.99982 17.5229 9.79067 22 11.9998 22Z"
        stroke="#19D9F5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.92871 5.07104C6.73836 6.8807 9.23836 8 11.9998 8C14.7612 8 17.2612 6.8807 19.0709 5.07104"
        stroke="#19D9F5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0709 18.9289C17.2612 17.1193 14.7612 16 11.9998 16C9.23836 16 6.73836 17.1193 4.92871 18.9289"
        stroke="#19D9F5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LangIcon
