import React from 'react'
import { IconProps } from '../Icon'

const MenuCloseIcon: React.FC<IconProps> = ({ color, size = 18 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 28 28" fill="none">
      <rect
        width="36.0823"
        height="3.43641"
        transform="matrix(0.705685 0.708525 -0.705685 0.708525 2.4248 0)"
        fill="#19D9F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.5752 0L28.0002 2.43478L2.5375 28L0.112477 25.5652L25.5752 0Z"
        fill="#19D9F5"
      />
    </svg>
  )
}

export default MenuCloseIcon
