import { useCallback, useEffect, useState } from 'react'
import { createFactory } from '@soh-cool/client-sdk'
// import { createFactory } from '../client-js/factory'
import { PoolType } from '@soh-cool/client-sdk/dist/types'
// import { Direction, PoolType } from '../client-js/types'
import { useWalletState } from '../store/wallet/hooks'
import { useWallet } from '@solana/wallet-adapter-react'

export const useLPInfo = () => {
  const [poolInfo, setPoolInfo] = useState<any[] | null>()
  const { address, isConnected } = useWalletState()
  const { publicKey, adapter } = useWallet()
  const raydiumFactory = createFactory(PoolType.Raydium)
  const poolOper = raydiumFactory.getPoolOper()

  const fetchPool = useCallback(async () => {
    if (adapter?.publicKey) {
      const poolArr: any[] = []
      const symbol = 'SOL-USDT-V4_PV4'
      const lpPoolInfo = poolOper.getPools()
      if (lpPoolInfo) {
        lpPoolInfo.forEach((item: any) => {
          if (item.symbol === symbol) {
            const poolItem = { ...item }
            poolArr.push(poolItem)
          }
        })
      }
      setPoolInfo(poolArr)
    } else {
      console.log('publicKey is NULL')
    }
  }, [PoolType.Raydium, address, isConnected, adapter])
  useEffect(() => {
    if (address && isConnected && adapter && publicKey) {
      fetchPool()
    } else {
      setPoolInfo(null)
    }
  }, [PoolType.Raydium, address, isConnected, adapter])
  return poolInfo
}
