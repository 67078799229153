import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'

interface BusinessProps {
  disabled?: boolean
  onClick?: () => void
  type?: string
}

const ButtonWarp: React.FC<BusinessProps> = ({ type, disabled, onClick }) => {
  return (
    <BtnWarp>
      {disabled ? (
        <Button className={'btn'} disabled={disabled}>
          <Dots>{type}</Dots>
        </Button>
      ) : (
        <Button className={'btn'} onClick={onClick} disabled={disabled}>
          {type}
        </Button>
      )}
      {/*<Button className={'btn'} onClick={onClick} disabled={disabled}>
        {type}
      </Button>*/}
    </BtnWarp>
  )
}

const BtnWarp = styled.div`
  .btn {
    width: 100%;
    height: 40px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    background-color: transparent;
    background-image: linear-gradient(94.19deg, #e000e4 4.18%, #14def5 88.14%);
    border-radius: 4px;
    border: none;
  }
  .ant-btn[disabled] {
    color: rgba(100, 90, 105, 1);
    background: transparent;
    border: 1px solid #645a69;
  }
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

const BusinessButton = React.memo(ButtonWarp, (prevProps, nextProps) => {
  return !!(prevProps.type === nextProps.type && prevProps.disabled === nextProps.disabled && prevProps.onClick)
})

export default BusinessButton
