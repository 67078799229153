import React from 'react'
import styled from 'styled-components'
import useMobile from '../../hooks/useMobile'

interface PageTitleProps {
  title: string
  desp: string
  tips?: string
}

const PageTitle: React.FC<PageTitleProps> = ({ title, tips, desp }) => {
  const isMobile = useMobile()

  return (
    <>
      <StyledPageTitle isMobile={isMobile}>
        <StyledTitle isMobile={isMobile}>
          {title}
          <span>{tips}</span>
        </StyledTitle>
        <StyledTips isMobile={isMobile}>{desp}</StyledTips>
      </StyledPageTitle>
    </>
  )
}

interface PageProp {
  isMobile: boolean
}

const StyledPageTitle = styled.div<PageProp>`
  position: relative;
  padding-top: ${(props) => (props.isMobile ? '20px' : '60px')};
  margin-bottom: ${(props) => (props.isMobile ? '20px' : '40px')};
  z-index: 1;
`

const StyledTitle = styled.div<PageProp>`
  color: #fff5ff;
  font-weight: 800;
  font-size: ${(props) => (props.isMobile ? '24px' : '64px')};
  line-height: ${(props) => (props.isMobile ? '30px' : '80px')};
  & > span {
    margin-left: 10px;
    font-size: ${(props) => (props.isMobile ? '18px' : '30px')};
  }
`

const StyledTips = styled.div<PageProp>`
  margin-top: 8px;
  color: #d8ced8;
  font-size: ${(props) => (props.isMobile ? '16px' : '20px')};
  font-weight: 600;
  line-height: ${(props) => (props.isMobile ? '14px' : '36px')};
`

export default PageTitle
