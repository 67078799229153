import React, { useEffect, useState, Suspense, useMemo } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import { Affix } from 'antd'
import { lightTheme as theme } from '../theme'
import ModalsProvider from '../contexts/Modals'
import Fallback from 'components/Fallback'
import TopBar from 'components/TopBar'
import Home from './Home'
import Farm from './Farm'
import Yield from './Yield'
import Notice from './Notice'
import Footer from '../components/Footer'
import { Provider } from 'react-redux'
import store from '../store'
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { clusterApiUrl } from '@solana/web3.js'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { getPhantomWallet } from '@solana/wallet-adapter-wallets'

import useMobile from '../hooks/useMobile'

import './App.css'

const App: React.FC = () => {
  const isMobile = useMobile()
  const network = WalletAdapterNetwork.Mainnet
  const endpoint = useMemo(() => clusterApiUrl(network), [network])
  // const endpoint = 'https://icy-silent-resonance.solana-mainnet.quiknode.pro/'
  const walletList = useMemo(() => [getPhantomWallet()], [network])
  console.log('endpoint', endpoint)
  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={walletList}>
        <Provider store={store}>
          <Suspense fallback={<Fallback />}>
            <Providers>
              <Router>
                <Switch>
                  <StyleLayout>
                    <Affix offsetTop={0} className="sohCool-affix">
                      <StyledHeader>
                        <TopBar />
                      </StyledHeader>
                    </Affix>
                    <StyledContent>
                      <Route path="/" exact>
                        <Home />
                      </Route>
                      {/*<Route path="/farm" exact>
                        <Farm />
                      </Route>*/}
                      <Route path="/yield" exact>
                        <Yield />
                      </Route>
                      <Route path="/notice" exact>
                        <Notice />
                      </Route>
                    </StyledContent>
                    <Footer />
                  </StyleLayout>
                </Switch>
              </Router>
            </Providers>
          </Suspense>
        </Provider>
      </WalletProvider>
    </ConnectionProvider>
  )
}

const Providers: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <ModalsProvider>{children}</ModalsProvider>
    </ThemeProvider>
  )
}

const StyleLayout = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const StyledHeader = styled.div`
  padding: 0;
  min-height: 72px;
`

const StyledContent = styled.div`
  min-height: calc(100vh - 72px);
  background: linear-gradient(179.94deg, #221638 40.68%, #032551 83.86%);
  flex: 1;
  color: #fff;
`

export default App
