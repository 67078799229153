import React from 'react'
import { IconProps } from '../Icon'

const DiscordIcon: React.FC<IconProps> = ({ color, size = 24 }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 48 48" fill="none">
      <rect width="48" height="48" rx="8" fill="url(#paint0_linear_3358_4914)" />
      <path
        d="M24 14.9802C18.423 14.9802 14.517 17.3761 14.517 17.3761C16.6653 15.4593 20.3978 14.3485 20.3978 14.3485L20.0506 14C16.5351 14.0653 13.3452 16.5048 13.3452 16.5048C9.7646 23.9976 10.0033 30.4884 10.0033 30.4884C12.9111 34.2784 17.2512 33.9952 17.2512 33.9952L18.7268 32.122C16.1228 31.5557 14.4736 29.2469 14.4736 29.2469C14.4736 29.2469 18.3796 31.926 24 31.926C29.6204 31.926 33.5265 29.2469 33.5265 29.2469C33.5265 29.2469 31.8772 31.5775 29.2732 32.122L30.7488 33.9952C30.7488 33.9952 35.0672 34.2784 37.9967 30.4884C37.9967 30.4884 38.2354 24.0194 34.6332 16.5048C34.6332 16.5048 31.4432 14.0653 27.9278 14L27.5806 14.3485C27.5806 14.3485 31.3347 15.4593 33.4614 17.3761C33.5048 17.3761 29.577 14.9802 24 14.9802ZM19.7034 22.8868C21.0488 22.8868 22.1555 24.0847 22.1338 25.5441C22.1338 26.9816 21.0488 28.2014 19.7034 28.2014C18.3796 28.2014 17.2946 26.9816 17.2946 25.5441C17.2946 24.0847 18.3362 22.8868 19.7034 22.8868ZM28.3835 22.8868C29.7289 22.8868 30.8139 24.0847 30.8139 25.5441C30.8139 26.9816 29.7289 28.2014 28.3835 28.2014C27.0598 28.2014 25.9747 26.9816 25.9747 25.5441C25.9747 24.0847 27.0164 22.8868 28.3835 22.8868Z"
        fill="white"
      />
      <rect
        x="0.25"
        y="0.25"
        width="47.5"
        height="47.5"
        rx="7.75"
        stroke="url(#paint1_linear_3358_4914)"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient id="paint0_linear_3358_4914" x1="4" y1="3" x2="48" y2="48" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.13" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear_3358_4914" x1="2.5" y1="4" x2="46" y2="48" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A7A8A8" />
          <stop offset="0.536458" stopColor="white" stopOpacity="0.18" />
          <stop offset="1" stopColor="white" stopOpacity="0.9" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default DiscordIcon
