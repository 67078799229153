import React from 'react'
import { IconProps } from '../Icon'

const FarmArrowIcon: React.FC<IconProps> = ({ style, size = 24 }) => {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none">
      <path d="M4.5 7.5L12 15L19.5 7.5" stroke="#958A95" strokeWidth="2" />
    </svg>
  )
}

export default FarmArrowIcon
