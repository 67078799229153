import React from 'react'
import { IconProps } from '../Icon'

const FarmArrowIcon: React.FC<IconProps> = ({ style }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" style={style}>
      <path
        d="M9.33301 2H13.9997V6.66667"
        stroke="#19D9F5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 9.82457V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3C2 2.44772 2.44772 2 3 2H6"
        stroke="#19D9F5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.59961 7.39999L13.6996 2.29999"
        stroke="#19D9F5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FarmArrowIcon
