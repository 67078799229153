import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import GetCoin from '../GetCoin'
import useMobile from '../../hooks/useMobile'

interface AmountInputContentProp {
  balance?: number
  type: string
  percentList: string[]
  pool: any
  getAmountVal: (val: string) => void
}

const AmountInputContent: React.FC<AmountInputContentProp> = ({ balance, type, percentList, pool, getAmountVal }) => {
  const isMobile = useMobile()
  const [active, setActive] = useState<number>(10)
  const [inputVal, setInputVal] = useState<string>('')

  const handleClick = (index: number) => {
    setActive(index === active ? 10 : index)
  }

  const handleInput = (e: any) => {
    const val = e.target.value.replace(/[^0-9]{0,1}(\d*(?:\.\d{0,4})?).*$/g, '$1')
    getAmountVal(val)
    setInputVal(val)
    console.log(val)
  }

  return (
    <AmountWarp>
      <StyledAmountArea isMobile={isMobile}>
        <StyledBalance>balance: {balance}</StyledBalance>
        <StyledCoinInfo>
          <StyledAmountInput type="text" placeholder={'0.00'} value={inputVal} onChange={(e) => handleInput(e)} />
          <StyledCoinName>
            <StyledMaxBtn>Max</StyledMaxBtn>
            <StyledName>{pool?.name ?? '--'}</StyledName>
          </StyledCoinName>
        </StyledCoinInfo>
      </StyledAmountArea>
      {type === 'stake' ? (
        <StyledAmountTip>
          <GetCoin coin={pool?.name ?? '--'} />
          <StyledTips>No tokens to stake get {pool.name ?? '--'}</StyledTips>
        </StyledAmountTip>
      ) : type === 'deposit' ? (
        <StyledDeposit>
          You will receive: <span>xx USDT</span> / day
        </StyledDeposit>
      ) : null}
      <StyledPercentageList>
        {percentList.map((item, index) => {
          return (
            <PercentageBtn
              isMobile={isMobile}
              className={index === active ? 'btn-active' : ''}
              onClick={() => handleClick(index)}
              key={index}
            >
              {item}
            </PercentageBtn>
          )
        })}
      </StyledPercentageList>
    </AmountWarp>
  )
}

interface MobileProps {
  isMobile: boolean
}

const AmountWarp = styled.div`
  .textR {
    text-align: right;
  }
`

const StyledAmountArea = styled.div<MobileProps>`
  padding: ${(props) => (props.isMobile ? '16px' : '24px')};
  background-color: #101322;
  border-radius: 4px;
`

const StyledBalance = styled.div`
  margin-bottom: 8px;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  color: #958a95;
`

const StyledCoinInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledAmountInput = styled.input`
  width: 50%;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  border: none;
  outline: none;
  background-color: transparent;
  color: #ffffff;
  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`

const StyledCoinName = styled.div`
  & > span {
    vertical-align: middle;
  }
`

const StyledMaxBtn = styled.span`
  padding: 2px 12px;
  color: #19d9f5;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  border: 2px solid #19d9f5;
  border-radius: 4px;
  cursor: pointer;
`

const StyledName = styled.span`
  margin-left: 8px;
  color: #fff5ff;
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
`

const StyledAmountTip = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`

const StyledDeposit = styled.div`
  margin: 8px 8px 0 0;
  color: #b9b3b9;
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  & > span {
    color: #ffffff;
  }
`

const StyledTips = styled.div`
  display: block;
  font-size: 12px;
  line-height: 28px;
  font-weight: 600;
  color: #fc5a5a;
`

const StyledPercentageList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0 32px;
  .btn-active {
    color: rgb(216, 206, 216);
    background-color: rgba(255, 255, 255, 0.16);
    border: 1px solid #feeaff;
  }
`

const PercentageBtn = styled.div<MobileProps>`
  padding: ${(props) => (props.isMobile ? '0 12px' : '4px 24px')};
  color: rgba(216, 206, 216, 0.8);
  font-size: ${(props) => (props.isMobile ? '12px' : '14px')};
  line-height: ${(props) => (props.isMobile ? '24px' : '30px')};
  border: 1px solid rgba(254, 234, 255, 0.8);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
`

export default AmountInputContent
