import React from 'react'
import { IconProps } from '../Icon'

const ArrowIcon: React.FC<IconProps> = ({ color, size = 14 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none">
      <path d="M5 1H13V9" stroke="#b9b3b9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 13L11 3" stroke="#b9b3b9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default ArrowIcon
