import React from 'react'
import styled from 'styled-components'
import useMobile from '../../hooks/useMobile'

const ModalTitle: React.FC = ({ children }) => {
  const isMobile = useMobile()
  return <StyledModalTitle isMobile={isMobile}>{children}</StyledModalTitle>
}

interface MobileProp {
  isMobile: boolean
}

const StyledModalTitle = styled.div<MobileProp>`
  align-items: center;
  display: flex;
  height: ${(props) => props.theme.topBarSize}px;
  justify-content: space-between;
  padding: ${(props) => (props.isMobile ? '0 12px' : '0 24px')};
  border-bottom: 1px solid rgba(254, 234, 255, 0.1);
`

export default ModalTitle
