import React, { useState, useMemo, MouseEvent } from 'react'
import styled from 'styled-components'
import { Button, Row } from 'antd'
import PercentageLine from '../../../components/PercentageLine'
import YieldExpandedMobile from './YieldExpandMobile'
import { FarmArrowIcon } from '../../../components/IconImage'
import jfCoin from '../../../assets/img/jf.png'
import usdCoin from '../../../assets/img/usdt.png'
import YieldBtn from './YieldBtn'

interface ItemProp {
  pool: any
}

const YieldItemMobile: React.FC<ItemProp> = ({ pool }) => {
  const [isExpanding, setIsExpanding] = useState<boolean>(false)
  const handleClick = () => {
    console.log(11111)
    setIsExpanding(!isExpanding)
  }
  const handleClickHarvest = (event: MouseEvent) => {
    event.stopPropagation()
    console.log(22222)
  }

  return (
    <>
      <StyledYieldItem>
        <StyledYieldCoin className={'flex-group'}>
          <StyledAssets>
            <div>
              <PercentageLine size={4} gap={2} title0={'JF'} title1={'USDT'} rate0={50} rate1={50} />
            </div>
            <StyledAssetCoin>
              <StyledCoinGroup>
                <StyledFrontImg src={jfCoin} alt="jf" />
                <StyledBackImg src={usdCoin} alt="jf" />
              </StyledCoinGroup>
            </StyledAssetCoin>
            <StyledAssetName>
              <StyledLpName>JF - USDT</StyledLpName>
              <StyledTvl>SUSHI LP</StyledTvl>
            </StyledAssetName>
          </StyledAssets>
          <StyledHarvest onClick={(event) => handleClickHarvest(event)}>Harvest</StyledHarvest>
        </StyledYieldCoin>
        <StyledData onClick={handleClick}>
          <StyledDataGroup className={'flex-group'}>
            <StyledDGName>TVL</StyledDGName>
            <StyledDGData>$ 231,234.43</StyledDGData>
          </StyledDataGroup>
          <StyledDataGroup className={'flex-top flex-group'}>
            <StyledDGName>MaxROI/My ROI</StyledDGName>
            <StyledDGData>
              <StyledPoolData>
                JF- + 188.22% <span className={'spanData'}>/ + 188.22%</span>
              </StyledPoolData>
              <StyledPoolData>
                USDT- 0.0000 <span className={'spanData'}>/ --</span>
              </StyledPoolData>
            </StyledDGData>
          </StyledDataGroup>
          <StyledDataGroup className={'flex-group'}>
            <StyledDGName>Details</StyledDGName>
            <StyledColIcon className={isExpanding ? 'arrow-rotate' : ''}>
              <FarmArrowIcon />
            </StyledColIcon>
          </StyledDataGroup>
        </StyledData>
        <YieldExpandedMobile pool={pool} isExpanding={isExpanding} />
        <StyledBtnGroup>
          <YieldBtn pool={pool} isApprove={true} />
        </StyledBtnGroup>
      </StyledYieldItem>
    </>
  )
}

const StyledYieldItem = styled.div`
  margin-bottom: 20px;
  padding-top: 16px;
  border: 2px solid;
  border-image: linear-gradient(94.48deg, #e001e5 5.16%, #19d9f5 91.31%) 1;
  clip-path: inset(0 round 4px);
  background: linear-gradient(95.13deg, rgba(224, 1, 229, 0.1) 7.73%, rgba(20, 222, 245, 0.1) 95.19%);
  font-size: 14px;
  color: #d8ced8;
  &:last-of-type {
    margin-bottom: 0;
  }
  .flex-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .flex-top {
    display: flex;
    align-items: start;
    justify-content: space-between;
  }
  .ant-btn[disabled] {
    color: rgba(100, 90, 105, 1);
    background: transparent;
    border: 1px solid #645a69;
  }
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    color: #ffffff;
    background: linear-gradient(94.19deg, #e000e4 4.18%, #14def5 88.14%);
  }
  .arrow-rotate {
    transform: rotate(180deg) translateY(-50%);
  }
  .expand-mobile {
    height: 465px;
  }
`

const StyledYieldCoin = styled.div`
  padding: 0 16px 16px;
  border-bottom: 1px solid rgba(224, 1, 229, 0.2);
`

const StyledAssets = styled.div`
  display: flex;
`

const StyledAssetCoin = styled.div`
  margin: 0 8px 0 16px;
`

const StyledAssetName = styled.div``

const StyledLpName = styled.div`
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 800;
  line-height: 24px;
  color: #fff5ff;
`

const StyledTvl = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`

const StyledCoinGroup = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  & > img {
    position: absolute;
    width: 32px;
    height: 32px;
  }
`

const StyledFrontImg = styled.img`
  top: 0;
  left: 0;
  z-index: 1;
`

const StyledBackImg = styled.img`
  right: 0;
  bottom: 0;
`

const StyledHarvest = styled(Button)`
  padding: 1px 10px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  background-color: transparent;
  background-image: linear-gradient(94.19deg, #e000e4 4.18%, #14def5 88.14%);
  border-radius: 4px;
  border: none;
`

const StyledData = styled.div`
  padding: 10px 16px;
  border-bottom: 1px solid rgba(224, 1, 229, 0.2);
`

const StyledDataGroup = styled.div`
  position: relative;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
`

const StyledDGName = styled.div``

const StyledDGData = styled.div`
  color: #fff5ff;
  text-align: right;
`

const StyledPoolData = styled.div`
  .spanData {
    color: #97eacc;
  }
`

const StyledColIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: rotate(0deg) translateY(-40%);
  transform-origin: 50% -10%;
  transition: all 0.3s;
`

const StyledBtnGroup = styled.div`
  padding: 0 16px 16px;
`

export default YieldItemMobile
