import { useEffect, useState } from 'react'
import { Connection, clusterApiUrl } from '@solana/web3.js'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
// import debounce from 'debounce'

const network = WalletAdapterNetwork.Mainnet
const endpoint = clusterApiUrl(network)
const solanaCon = new Connection(endpoint, 'confirmed')

const useBlock = () => {
  const [block, setBlock] = useState<number | null>(0)
  useEffect(() => {
    const blockTime = 10
    const interval = setInterval(async () => {
      const slotNumber = await solanaCon.getSlot()
      console.log('latestSlotNumber', slotNumber)
      if (block !== slotNumber) {
        setBlock(slotNumber)
      }
    }, blockTime * 1000)

    return () => clearInterval(interval)
  }, [network])

  return block
}

export default useBlock
