import React from 'react'
import { IconProps } from '../Icon'

const NoticeCloseIcon: React.FC<IconProps> = ({ color, size = 18 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6727 11.2355C11.8534 11.0548 11.8534 10.7619 11.6727 10.5812L9.07616 7.98451L11.6729 5.38792C11.8535 5.20725 11.8535 4.91433 11.6729 4.73366L11.2665 4.32727C11.0858 4.14659 10.7929 4.14659 10.6122 4.32726L8.01552 6.92383L5.41896 4.32716C5.23829 4.14648 4.94537 4.14648 4.7647 4.32715L4.35831 4.73354C4.17764 4.91421 4.17763 5.20714 4.3583 5.38782L6.95484 7.98447L4.35816 10.581C4.17749 10.7617 4.17749 11.0546 4.35816 11.2353L4.76455 11.6417C4.94522 11.8224 5.23815 11.8224 5.41883 11.6417L8.01547 9.04515L10.6121 11.6419C10.7927 11.8225 11.0857 11.8225 11.2663 11.6419L11.6727 11.2355Z"
          fill="#FFF5FF"
        />
      </g>
    </svg>
  )
}

export default NoticeCloseIcon
