import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Menu } from 'antd'
import useMobile from '../../../hooks/useMobile'
import { MenuIcon, MenuCloseIcon, HomeMenuIcon, FarmMenuIcon, YieldMenuIcon } from 'components/IconImage'

const TopMenu: React.FC = () => {
  const isMobile = useMobile()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [showMenu, setShowMenu] = useState<boolean>(false)

  const openMenu = () => {
    setShowMenu(!showMenu)
  }

  return (
    <>
      {isMobile ? (
        <StyledMenuWrapper>
          <StyledMenuIconWrapper onClick={openMenu}>
            {showMenu ? <MenuCloseIcon /> : <MenuIcon />}
          </StyledMenuIconWrapper>
          {showMenu ? (
            <StyledMenuArea>
              <StyledMenuContent>
                <StyledMenuItem onClick={openMenu}>
                  <StyledLink className={pathname === '/' ? 'active' : ''} to="/">
                    <HomeMenuIcon />
                    {t('route.home')}
                  </StyledLink>
                </StyledMenuItem>
                {/*<StyledMenuItem onClick={openMenu}>
                  <StyledLink className={pathname === '/farm' ? 'active' : ''} to="/farm">
                    <FarmMenuIcon />
                    {t('route.farm')}
                  </StyledLink>
                </StyledMenuItem>*/}
                <StyledMenuItem onClick={openMenu}>
                  <StyledLink className={pathname === '/yield' ? 'active' : ''} to="/yield">
                    <YieldMenuIcon />
                    {t('route.yield')}
                  </StyledLink>
                </StyledMenuItem>
              </StyledMenuContent>
              <StyledOtherMenu>
                <StyledLink to="">Documents</StyledLink>
                <StyledLink to="">Explorer</StyledLink>
                <StyledLink to="">Security Audit</StyledLink>
                <StyledLink to="">Info</StyledLink>
              </StyledOtherMenu>
            </StyledMenuArea>
          ) : null}
        </StyledMenuWrapper>
      ) : (
        <AntdMenu forceSubMenuRender inlineIndent={0} selectedKeys={[pathname]} mode="horizontal">
          <Menu.Item key="/">
            <StyledLink className={pathname === '/' ? 'active' : ''} to="/">
              {t('route.home')}
            </StyledLink>
          </Menu.Item>
          {/*<Menu.Item key="/farm">
            <StyledLink className={pathname === '/farm' ? 'active' : ''} to="/farm">
              {t('route.farm')}
            </StyledLink>
          </Menu.Item>*/}
          <Menu.Item key="/yield">
            <StyledLink className={pathname === '/yield' ? 'active' : ''} to="/yield">
              {t('route.yield')}
            </StyledLink>
          </Menu.Item>

          {/* SecondMenu */}
          {/* <Menu.SubMenu key="SubMenu" title="FirstMenu">
        <Menu.Item key="/">
          <StyledExternalLink target="_self" href="/">
            SecondMenu
          </StyledExternalLink>
        </Menu.Item>
      </Menu.SubMenu> */}
        </AntdMenu>
      )}
    </>
  )
}

const StyledMenuIconWrapper = styled.div`
  display: flex;
`

const StyledMenuItem = styled.div`
  display: flex;
`

const StyledMenuArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  top: 72px;
  right: 0px;
  background: #2c1131;
`

const StyledMenuContent = styled.div`
  padding: 14px 22px;
  border-top: 1px solid rgba(254, 234, 255, 0.1);
  border-bottom: 1px solid rgba(254, 234, 255, 0.1);
`

const StyledOtherMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 22px;
`

const StyledMenuWrapper = styled.div`
  display: flex;
`

const AntdMenu = styled(Menu)`
  margin: 0 0 0 40px;
  border-bottom: none;
  background-color: unset;
  width: 100%;
  height: 72px;
  line-height: 72px;
  .ant-menu-item {
    min-width: auto !important;
  }
  .ant-menu-item a {
    color: #b9b3b9;
  }
  .ant-menu-item-selected a,
  .ant-menu-item-active a {
    color: #b9b3b9;
    &:hover,
    &.active {
      color: #fff5ff;
    }
  }
  .ant-menu-item::after {
    border-bottom: none !important;
  }
  .ant-menu-item-selected::after {
    bottom: 1px !important;
    border-bottom: 4px solid #fff !important;
    border-image: linear-gradient(94.32deg, #e000e4 4.3%, #14def5 90.7%) 1 !important;
    transition: none;
  }
  .ant-menu-item:hover::after,
  .ant-menu-item-active::after {
    bottom: 1px !important;
    border-bottom: 4px solid #fff !important;
    border-image: linear-gradient(94.32deg, #e000e4 4.3%, #14def5 90.7%) 1 !important;
    transition: none;
  }
  .ant-menu-submenu::after {
    border-bottom: none !important;
  }
  .ant-menu-submenu-title {
    color: #b9b3b9;
    :hover {
      color: #fff5ff;
    }
  }
`

const StyledLink = styled(Link)`
  color: #b9b3b9;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  white-space: pre;

  &:hover,
  &.active {
    color: #fff5ff;
  }
  & > svg {
    margin-right: 28px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    height: 56px;
    display: flex;
    align-items: center;
  }
`

export default TopMenu
