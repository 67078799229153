import { configureStore } from '@reduxjs/toolkit'
import wallet from './wallet/reducer'
import compYield from './compYield/reducer'

const store = configureStore({
  reducer: {
    wallet,
    compYield,
  },
})

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
