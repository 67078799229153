import React from 'react'
import { IconProps } from '../Icon'

const YieldHomeIcon: React.FC<IconProps> = ({ color, size = 50 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 50 50" fill="none">
      <g opacity="0.6" filter="url(#filter0_bi_2840_3651)">
        <rect width="50" height="50" rx="10" fill="url(#paint0_linear_2840_3651)" fillOpacity="0.2" />
        <rect x="1" y="1" width="48" height="48" rx="9" stroke="url(#paint1_linear_2840_3651)" strokeWidth="2" />
      </g>
      <g filter="url(#filter1_i_2840_3651)">
        <path
          d="M18.1686 36.3549C15.3616 36.3549 13.0736 34.163 13.0679 31.4686C13.0651 30.1749 13.6107 28.9085 14.5646 27.994L19.3398 23.4007C19.5392 23.2097 19.8053 23.1044 20.0885 23.1044C20.3719 23.1044 20.6382 23.2099 20.8383 23.4012L22.9484 25.4307L31.5218 17.1826L29.5722 15.3071C29.3967 15.1058 29.3112 14.8519 29.3314 14.5907C29.3521 14.3233 29.4809 14.0794 29.6939 13.9039C29.8895 13.7445 30.1343 13.6578 30.3866 13.6585C30.6371 13.6585 30.8794 13.7433 31.0702 13.8972L36.457 19.0793C36.5515 19.1715 36.6264 19.282 36.677 19.404C36.7276 19.526 36.7529 19.657 36.7513 19.789C36.7481 20.0561 36.636 20.3068 36.436 20.4944C36.2414 20.6771 35.9839 20.7801 35.7109 20.7845C35.4245 20.7845 35.1583 20.6793 34.9581 20.4884L33.0044 18.6082L24.4299 26.8563L26.5568 28.9025C26.7521 29.0911 26.8599 29.3416 26.8599 29.6072C26.8599 29.8729 26.7521 30.1233 26.5564 30.3124L21.7814 34.905C20.8215 35.8347 19.5423 36.3494 18.1785 36.3551H18.1686V36.3549ZM16.0629 29.417C15.4956 29.961 15.1824 30.6859 15.1811 31.4581C15.1797 32.2305 15.4907 32.9567 16.0566 33.5029L16.069 33.5149C16.6405 34.0344 17.3879 34.3206 18.1734 34.3206C18.959 34.3206 19.7062 34.0344 20.2779 33.5149L24.3195 29.627L20.082 25.5504L16.0629 29.417ZM27.622 36.3549C27.3297 36.3549 27.0578 36.2437 26.8561 36.0418C26.6683 35.8538 26.5651 35.6077 26.5655 35.3486C26.5663 34.7946 27.0405 34.3433 27.6224 34.3426H35.7157C35.9998 34.343 36.2666 34.4491 36.4664 34.642C36.6632 34.8317 36.7714 35.0831 36.771 35.3498C36.771 35.9043 36.2975 36.3549 35.7157 36.3549H27.622ZM31.6688 31.1649C31.3765 31.1649 31.1046 31.0538 30.9031 30.8524C30.7153 30.6642 30.612 30.4177 30.6125 30.1577C30.6125 29.604 31.0865 29.1533 31.6692 29.1525H35.7157C36 29.153 36.2668 29.259 36.4666 29.4515C36.6632 29.6411 36.7714 29.8922 36.771 30.1589C36.771 30.7139 36.2975 31.1649 35.7157 31.1649H31.6688V31.1649Z"
          fill="#E001E5"
        />
      </g>
      <defs>
        <filter
          id="filter0_bi_2840_3651"
          x="-100"
          y="-100"
          width="250"
          height="250"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="50" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2840_3651" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2840_3651" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2840_3651" />
        </filter>
        <filter
          id="filter1_i_2840_3651"
          x="13.0679"
          y="13.6585"
          width="23.7031"
          height="23.6966"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2840_3651" />
        </filter>
        <linearGradient
          id="paint0_linear_2840_3651"
          x1="0"
          y1="24.8768"
          x2="50.8333"
          y2="24.8768"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E001E5" stopOpacity="0.75" />
          <stop offset="0.48854" stopColor="#E001E5" stopOpacity="0.5" />
          <stop offset="1" stopColor="#E001E5" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2840_3651"
          x1="0"
          y1="0"
          x2="56.7331"
          y2="40.5888"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#19D9F5" />
          <stop offset="0.40625" stopColor="#E001E5" stopOpacity="0" />
          <stop offset="0.697917" stopColor="#E001E5" stopOpacity="0" />
          <stop offset="1" stopColor="#E001E5" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default YieldHomeIcon
