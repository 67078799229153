import { useCallback, useState } from 'react'
import { createFactory } from '@soh-cool/client-sdk'
// import { createFactory } from '../client-js/factory'
import { Direction, PoolType } from '@soh-cool/client-sdk/dist/types'
// import { Direction, PoolType } from '../client-js/types'
import { useWallet } from '@solana/wallet-adapter-react'
import { programId, sendTransaction } from '../utils/utils'
import { PublicKey, Transaction } from '@solana/web3.js'
import { NATIVE_SOL, TOKENS } from '../utils/token'
import { createSOLTokenAccount } from '../utils/utils'
import { PoolTokenPairBase } from '@soh-cool/client-sdk/dist/pools/types'
// import { PoolTokenPairBase } from '../client-js/pools/types'
import { closeAccount } from '@project-serum/serum/lib/token-instructions'
import * as globalStore from '@soh-cool/client-sdk/dist/globalStore'
// import * as globalStore from '../client-js/globalStore'
import { NATIVE_MINT } from '@solana/spl-token'
// import {
//   createAssociatedTokenAccountIfNotExist,
//   createNativeWrapAssociatedAccountIfNotExistV1,
// } from '../client-js/utils/splToken'
import {
  createAssociatedTokenAccountIfNotExist,
  createNativeWrapAssociatedAccountIfNotExistV1,
} from '@soh-cool/client-sdk/dist/utils/splToken'

const useWithdraw = () => {
  const { wallet, connected, publicKey, adapter } = useWallet()
  const ProgramId = new PublicKey(programId)
  const raydiumFactory = createFactory(PoolType.Raydium)

  const connection = globalStore.getConnection()
  const handleWithdraw = useCallback(
    async (
      distMintAddress: string,
      distPairMintAddress: string,
      pool: PoolTokenPairBase,
      amount: string,
      direction: Direction
    ) => {
      // const transaction = new Transaction()
      // let destAccount: PublicKey
      // let destPairAccount: PublicKey
      if (adapter?.publicKey) {
        const userOper = raydiumFactory.getUserOper(adapter?.publicKey, ProgramId)
        /*if (distMintAddress === NATIVE_MINT.toString()) {
          console.log('sol', adapter.publicKey, amount, distMintAddress)
          destAccount = await createNativeWrapAssociatedAccountIfNotExistV1(adapter.publicKey, +amount)
          console.log('destAccount create success', destAccount.toBase58())
        } else {
          destAccount = await createAssociatedTokenAccountIfNotExist(
            new PublicKey(distMintAddress),
            adapter.publicKey,
            adapter.publicKey
          )
          console.log('destAccount create success (else)', destAccount)
        }

        if (distPairMintAddress === NATIVE_MINT.toString()) {
          destPairAccount = await createNativeWrapAssociatedAccountIfNotExistV1(adapter.publicKey, +amount)
          console.log('destPairAccount create success', destPairAccount.toBase58())
        } else {
          destPairAccount = await createAssociatedTokenAccountIfNotExist(
            new PublicKey(distPairMintAddress),
            adapter.publicKey,
            adapter.publicKey
          )
          console.log('destPairAccount create success（else）', destPairAccount.toBase58())
        }

        console.log(
          'withdraw参数',
          destAccount.toBase58(),
          destPairAccount.toBase58(),
          pool,
          PoolType.Raydium,
          amount,
          direction
        )*/
        // const minAmount = (+amount * 0.8).toString()
        const result = await userOper.withdraw(pool, PoolType.Raydium, amount, direction)
        console.log('result', result)

        //closeAccount
        /*if (distMintAddress === NATIVE_MINT.toString()) {
          transaction.add(
            closeAccount({
              source: destAccount,
              destination: adapter.publicKey,
              owner: adapter.publicKey,
            })
          )
        }
        if (distPairMintAddress === NATIVE_SOL.mintAddress) {
          transaction.add(
            closeAccount({
              source: destPairAccount,
              destination: adapter.publicKey,
              owner: adapter.publicKey,
            })
          )
        }
        const txid = await sendTransaction(connection, adapter, transaction)
        console.log('closeAccount', txid)*/
      } else {
        console.log('publicKey is NULL')
      }
    },
    [connected, publicKey, adapter]
  )
  return { onWithdraw: handleWithdraw }
}
export default useWithdraw
