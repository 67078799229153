// lib
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import jfCoin from '../../../assets/img/jf.png'
import usdCoin from '../../../assets/img/usdt.png'
import { CoinIcon } from '../../../components/IconImage'

// hooks
import { useTranslation } from 'react-i18next'
import useMobile from '../../../hooks/useMobile'
import useModal from '../../../hooks/useModal'

interface DetailProps {
  pool: any
  poolData: any
}

const YieldExpandedMyDetail: React.FC<DetailProps> = ({ pool, poolData }) => {
  const { t } = useTranslation()
  const isMobile = useMobile()

  return (
    <>
      {isMobile ? (
        <StyledPoolMobile>
          <StyledPoolGroup className={'flex-group'}>
            <StyledPName>Pool Proportion</StyledPName>
            <StyledPData>{poolData?.myAPro ?? '0'} %</StyledPData>
          </StyledPoolGroup>
          <StyledPoolGroup className={'flex-group'}>
            <StyledPName>Earning</StyledPName>
            <StyledPData>{poolData?.myAEarn ?? '0'}</StyledPData>
          </StyledPoolGroup>
          <StyledPoolGroup className={'flex-group'}>
            <StyledPName>Deposits</StyledPName>
            <StyledPData>{poolData?.myADeposit ?? '0'}</StyledPData>
          </StyledPoolGroup>
          <StyledPoolGroup className={'flex-group'}>
            <StyledPName>IL(0 means no IL)</StyledPName>
            <StyledPData>{poolData?.myAIL ?? '0'} JF</StyledPData>
          </StyledPoolGroup>
          <StyledPoolGroup className={'flex-group'}>
            <StyledPName>IL Compensation</StyledPName>
            <StyledPData>{poolData?.myAILCom ?? '0'} USDT</StyledPData>
          </StyledPoolGroup>
        </StyledPoolMobile>
      ) : (
        <StylePoolWrapper>
          <StyledHead>
            <Row gutter={[16, 0]} align={'middle'} className={'a-row'}>
              <Col span={4} offset={3} className={'a-col ap-right'}>
                Pool Proportion
              </Col>
              <Col span={3} className={'a-col ap-right'}>
                Earning
              </Col>
              <Col span={4} className={'a-col ap-right'}>
                Deposits
              </Col>
              <Col span={5} className={'a-col ap-right'}>
                IL(0 means no IL)
              </Col>
              <Col span={5} className={'a-col ap-right'}>
                IL Compensation
              </Col>
            </Row>
          </StyledHead>
          <StyledBody>
            <Row gutter={[16, 0]} align={'middle'} className={'a-row ap-row'}>
              <Col span={3} className={'a-col'}>
                <StyledCoin>
                  <StyledImg>
                    <CoinIcon coinAddr={pool?.tokenA.mintAddress} />
                  </StyledImg>
                  <StyledData>{pool?.otherInfo?.coin.symbol ?? '--'}</StyledData>
                </StyledCoin>
              </Col>
              <Col span={4} className={'a-col ap-right'}>
                <StyledData>{poolData?.myAPro ?? '0'} %</StyledData>
              </Col>
              <Col span={3} className={'a-col ap-right'}>
                <StyledData>{poolData?.myAEarn ?? '0'}</StyledData>
              </Col>
              <Col span={4} className={'a-col ap-right'}>
                <StyledData>{poolData?.myADeposit ?? '0'}</StyledData>
              </Col>
              <Col span={5} className={'a-col ap-right'}>
                <StyledData>
                  {poolData?.myAIL ?? '0'} {pool?.otherInfo?.coin.symbol ?? '--'}
                </StyledData>
              </Col>
              <Col span={5} className={'a-col ap-right'}>
                <StyledData>
                  {poolData?.myAILCom ?? '0'} {pool?.otherInfo?.pc.symbol ?? '--'}
                </StyledData>
              </Col>
            </Row>
          </StyledBody>
          <StyledBody>
            <Row gutter={[16, 0]} align={'middle'} className={'a-row'}>
              <Col span={3} className={'a-col'}>
                <StyledCoin>
                  <StyledImg>
                    <CoinIcon coinAddr={pool?.tokenB.mintAddress} />
                  </StyledImg>
                  <StyledData>{pool?.otherInfo?.pc.symbol ?? '--'}</StyledData>
                </StyledCoin>
              </Col>
              <Col span={4} className={'a-col ap-right'}>
                <StyledData>0%</StyledData>
              </Col>
              <Col span={3} className={'a-col ap-right'}>
                <StyledData>0</StyledData>
              </Col>
              <Col span={4} className={'a-col ap-right'}>
                <StyledData>0</StyledData>
              </Col>
              <Col span={5} className={'a-col ap-right'}>
                <StyledData>
                  {poolData?.tokenBIL ?? '0.0000'} {pool?.otherInfo?.pc.symbol ?? '--'}
                </StyledData>
              </Col>
              <Col span={5} className={'a-col ap-right'}>
                <StyledData>
                  {poolData?.tokenBILCom ?? '0.0000'} {pool?.otherInfo?.coin.symbol ?? '--'}
                </StyledData>
              </Col>
            </Row>
          </StyledBody>
        </StylePoolWrapper>
      )}
    </>
  )
}

const StylePoolWrapper = styled.div`
  font-size: 14px;
  font-weight: 600;
  .a-row {
    padding: 0 24px;
  }
  .a-col {
    padding: 12px 0;
  }
  .ap-right {
    text-align: right;
  }
`

const StyledHead = styled.div`
  color: #b9b3b9;
  background-color: rgba(60, 57, 66, 0.3);
`

const StyledBody = styled.div`
  border-bottom: 1px solid rgba(254, 215, 255, 0.3);
  &:last-of-type {
    border-bottom: none;
  }
`

const StyledCoin = styled.div`
  display: flex;
  align-items: center;
`

const StyledImg = styled.div`
  width: 24px;
  height: 24px;
  font-size: 0;
  line-height: 0;
  & > img {
    width: 100%;
  }
`

const StyledData = styled.div`
  margin-left: 8px;
  font-weight: 700;
  color: #ffffff;
`

const StyledPoolMobile = styled.div`
  font-size: 12px;
  font-weight: 600;
`

const StyledPoolGroup = styled.div`
  margin-bottom: 8px;
  line-height: 20px;
`

const StyledPName = styled.div`
  color: #d8ced8;
`

const StyledPData = styled.div`
  color: #fff5ff;
  text-align: right;
`

export default YieldExpandedMyDetail
