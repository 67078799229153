import React, { useState } from 'react'
import styled from 'styled-components'

const TotalLocked: React.FC = () => {
  return (
    <>
      <StyledLocked>
        <StyledLockName>Total Value Locked</StyledLockName>
        <StyledLockData>$ 3,456,453.34</StyledLockData>
      </StyledLocked>
    </>
  )
}

const StyledLocked = styled.div`
  margin: 50px 0;
  text-align: center;
`

const StyledLockName = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  color: #d8ced8;
`

const StyledLockData = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: #fff5ff;
  line-height: 30px;
`

export default TotalLocked
