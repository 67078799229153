import React from 'react'
import styled from 'styled-components'
import useMobile from '../../hooks/useMobile'

const ModalContent: React.FC = ({ children }) => {
  const isMobile = useMobile()
  return <StyledModalContent isMobile={isMobile}>{children}</StyledModalContent>
}

interface MobileProp {
  isMobile: boolean
}

const StyledModalContent = styled.div<MobileProp>`
  padding: ${(props) => (props.isMobile ? '12px' : '24px')};
  & > div:last-child {
    margin-bottom: 0;
  }
`

export default ModalContent
