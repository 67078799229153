import BigNumber from 'bignumber.js'
import {
  Connection,
  PublicKey,
  clusterApiUrl,
  Transaction,
  Account,
  SystemProgram,
  TransactionSignature,
  Keypair,
  Signer,
} from '@solana/web3.js'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { ACCOUNT_LAYOUT } from '@soh-cool/client-sdk/dist/layout/raydiumLayout'
// import { ACCOUNT_LAYOUT } from '../client-js/layout/raydiumLayout'
import { closeAccount, initializeAccount } from '@project-serum/serum/lib/token-instructions'
import { NATIVE_SOL, TOKENS } from './token'
import { TokenAmount } from './safe-math'
import { Token } from '@solana/spl-token'

const network = WalletAdapterNetwork.Mainnet
console.log('network', network)
const endpoint = clusterApiUrl(network)
console.log('endpoint', endpoint)
const solanaCon = new Connection(endpoint, 'confirmed')
const transaction = new Transaction()
const signers: Account[] = []

export const programId = '79P7NBiGL9ctYrPatkfPSMauQhSZt4uYPynHFR7KqBwz'
export const TOKEN_PROGRAM_ID = new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA')
export const ASSOCIATED_TOKEN_PROGRAM_ID = new PublicKey('ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL')

export function getSolscanLink(
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block' | 'tokenAddr'
): string {
  const prefix = `https://solscan.io`
  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'tokenAddr': {
      return `${prefix}/tokenAddr/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

export const getSOLBalance = async (address: string) => {
  const addr = new PublicKey(address)
  const balance = await solanaCon.getBalance(addr)
  console.log('balance', balance)
  return balance
}

export const toFixedNumber = (num: number, digits: number, base?: number): number => {
  const pow = Math.pow(base || 10, digits)
  return Math.round(num * pow) / pow
}

export function getBigNumber(num: any) {
  return num === undefined || num === null ? 0 : parseFloat(num.toString())
}

export const createProgramAccountIfNotExist = async (
  connection: Connection,
  account: string | undefined | null,
  owner: PublicKey,
  programId: PublicKey,
  lamports: number | null,
  layout: any,

  transaction: Transaction,
  signer: Signer[]
) => {
  let publicKey

  if (account) {
    publicKey = new PublicKey(account)
  } else {
    const newAccount = new Account()
    publicKey = newAccount.publicKey

    transaction.add(
      SystemProgram.createAccount({
        fromPubkey: owner,
        newAccountPubkey: publicKey,
        lamports: lamports ?? (await connection.getMinimumBalanceForRentExemption(layout.span)),
        space: layout.span,
        programId,
      })
    )
    signer.push(newAccount)
  }
  return publicKey
}

export const createTokenAccountIfNotExist = async (
  connection: Connection,
  account: string | undefined | null,
  owner: PublicKey,
  mintAddress: string,
  lamports: number,
  transaction: Transaction,
  signer: Signer[]
) => {
  let publicKey

  if (account) {
    publicKey = new PublicKey(account)
  } else {
    publicKey = await createProgramAccountIfNotExist(
      connection,
      account,
      owner,
      TOKEN_PROGRAM_ID,
      lamports,
      ACCOUNT_LAYOUT,
      transaction,
      signer
    )

    transaction.add(
      initializeAccount({
        account: publicKey,
        mint: new PublicKey(mintAddress),
        owner,
      })
    )
    const tx = await sendTransaction(connection, owner, transaction, signers)
    console.log(tx)
  }

  return publicKey
}

export async function createAssociatedTokenAccountIfNotExist(
  account: string | undefined | null,
  owner: PublicKey,
  mintAddress: string,
  transaction: Transaction,
  atas: string[] = []
) {
  let publicKey
  if (account) {
    publicKey = new PublicKey(account)
  }

  const mint = new PublicKey(mintAddress)
  const ata = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, mint, owner, true)

  if ((!publicKey || !ata.equals(publicKey)) && !atas.includes(ata.toBase58())) {
    transaction.add(
      Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        mint,
        ata,
        owner,
        owner
      )
    )
    atas.push(ata.toBase58())
  }

  return ata
}

export const createSOLTokenAccount = async (connection: Connection, owner: PublicKey, amount: string, token: any) => {
  const transaction = new Transaction()
  const signers: Signer[] = []
  const amountIn = new TokenAmount(amount, token.decimals, false)
  console.log('owner', owner, connection)
  const wrappedSolAccount = await createTokenAccountIfNotExist(
    connection,
    null,
    owner,
    TOKENS.WSOL.mintAddress,
    getBigNumber(amountIn.wei) + 1e7,
    transaction,
    signers
  )
  console.log('wrappedSolAccount', wrappedSolAccount)
  return wrappedSolAccount
  /*if (tokenMint === NATIVE_SOL.mintAddress) {
  } else {
    wrappedSolAccount = await createAssociatedTokenAccountIfNotExist(fromTokenAccount, owner, tokenMint, transaction)
  }*/
}

export async function sendTransaction(
  connection: Connection,
  wallet: any,
  transaction: Transaction,
  signers: Signer[] = []
) {
  const txid: TransactionSignature = await wallet.sendTransaction(transaction, connection, {
    signers,
    skipPreflight: true,
    preflightCommitment: 'confirmed',
  })

  return txid
}
