import React from 'react'
import { IconProps } from '../Icon'

const HomeMenuIcon: React.FC<IconProps> = ({ color, size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none">
      <path d="M32 0H0V32H32V0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M29.0671 29.0671V13.0671L15.7337 2.40039L2.40039 13.0671V29.0671H10.4004V17.0671H21.0671V29.0671H29.0671Z"
        stroke="#FFF5FF"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
      <path d="M16 29.3327V22.666" stroke="#FFF5FF" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default HomeMenuIcon
