import React, { useState, useMemo, MouseEvent, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, Button } from 'antd'
import PercentageLine from '../../../components/PercentageLine'
import YieldExpandedRow from './YieldExpandRow'
import { CoinIcon } from '../../../components/IconImage'
import { usePoolDataInfo } from '../../../hooks/usePoolDataInfo'
import { useCompYieldState } from '../../../store/compYield/hooks'

interface YieldProp {
  poolInfo: Record<any, any>
}

const YieldItem: React.FC<YieldProp> = ({ poolInfo }) => {
  const [isExpanding, setIsExpanding] = useState<boolean>(false)
  const [pool, setPool] = useState<Record<any, any>>()
  const [poolData, setPoolData] = useState<any>()
  const { fetchPool } = usePoolDataInfo()
  const { count } = useCompYieldState()
  const handleClick = () => {
    console.log(11111)
    setIsExpanding(!isExpanding)
  }
  const handleClickHarvest = (event: MouseEvent) => {
    event.stopPropagation()
    console.log(22222)
  }
  useEffect(() => {
    setPool(poolInfo)
    console.log('4545', poolInfo)
  }, [poolInfo])
  useMemo(async () => {
    try {
      const info = await fetchPool(poolInfo.symbol)
      console.log('7777', info)
      setPoolData(info)
    } catch (e) {
      console.log(e)
    }
  }, [poolInfo, count])
  return (
    <>
      <StyledYieldItem>
        <Row align={'middle'} className={'y-head'} gutter={[8, 0]} onClick={handleClick}>
          <Col className={'yb-col y-center'} span={3}>
            <StyledLpName>{pool?.tokenLP?.name ?? '--'}</StyledLpName>
            <StyledTvl>TVL</StyledTvl>
            <StyledTvl>$ 234,234.45</StyledTvl>
          </Col>
          <Col className={'yb-col'} span={4}>
            <StyledAssets>
              <div>
                <PercentageLine
                  title0={pool?.tokenA?.symbol ?? '--'}
                  title1={pool?.tokenB?.symbol ?? '--'}
                  rate0={50}
                  rate1={50}
                />
              </div>
              <StyledAssetCoin>
                <StyledCoinGroup>
                  <StyledCoinImg>
                    <CoinIcon coinAddr={pool?.tokenA.mintAddress} />
                  </StyledCoinImg>
                  <StyledCoinName>{pool?.otherInfo?.coin.symbol ?? '--'}</StyledCoinName>
                </StyledCoinGroup>
                <StyledCoinGroup>
                  <StyledCoinImg>
                    <CoinIcon coinAddr={pool?.tokenB.mintAddress} />
                  </StyledCoinImg>
                  <StyledCoinName>{pool?.otherInfo?.pc.symbol ?? '--'}</StyledCoinName>
                </StyledCoinGroup>
              </StyledAssetCoin>
            </StyledAssets>
          </Col>
          <Col className={'yb-col y-right'} span={3}>
            <StyledPoolData>{poolData?.userAPair ?? '0.0000'}</StyledPoolData>
            <StyledPoolData>{'0.0000'}</StyledPoolData>
          </Col>
          <Col className={'yb-col y-right'} span={4}>
            <StyledPoolData>{poolData?.userAQueue ?? '0.0000'}</StyledPoolData>
            <StyledPoolData>{'0.0000'}</StyledPoolData>
          </Col>
          <Col className={'yb-col y-right'} span={4}>
            <StyledPoolData>{poolData?.tokenAQuickPair ?? '0.0000'}</StyledPoolData>
            <StyledPoolData>{poolData?.tokenBQuickPair ?? '0.0000'}</StyledPoolData>
          </Col>
          {/*<Col className={'yb-col y-right'} span={5}>
            <StyledPoolData>
              + 188.22% <span className={'spanData'}>/ + 188.22%</span>
            </StyledPoolData>
            <StyledPoolData>
              0.0000 <span className={'spanData'}>/ --</span>
            </StyledPoolData>
          </Col>*/}
          <Col className={'yb-col y-right'} span={3}>
            <StyledAssetData>0.0000</StyledAssetData>
          </Col>
          <Col className={'yb-col y-right y-flex'} span={3}>
            {/*<StyledHarvest onClick={(event) => handleClickHarvest(event)}>Harvest</StyledHarvest>*/}
            <StyledGain>0.0000</StyledGain>
            {/*<StyledGainU>≈$ 0.021</StyledGainU>*/}
          </Col>
        </Row>
      </StyledYieldItem>
      <YieldExpandedRow pool={poolInfo} poolData={poolData} isExpanding={isExpanding} />
    </>
  )
}

const StyledYieldItem = styled.div`
  border-bottom: 1px solid rgba(254, 215, 255, 0.3);
  background-color: #1c2337;
  color: #fff5ff;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 14px;
  .ant-btn[disabled] {
    color: rgba(100, 90, 105, 1);
    background: transparent;
    border: 1px solid #645a69;
  }
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    color: #ffffff;
    background: linear-gradient(94.19deg, #e000e4 4.18%, #14def5 88.14%);
  }
`

const StyledLpName = styled.div`
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 800;
`

const StyledTvl = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`

const StyledAssets = styled.div`
  display: flex;
`

const StyledAssetCoin = styled.div`
  margin-left: 16px;
`

const StyledCoinGroup = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
`

const StyledCoinImg = styled.div`
  width: 24px;
  height: 24px;
  line-height: 0;
  font-size: 0;
  margin-right: 8px;
  & > img {
    width: 100%;
    line-height: 0;
    font-size: 0;
  }
`

const StyledCoinName = styled.div``

const StyledPoolData = styled.div`
  margin-bottom: 16px;
  &: last-of-type {
    margin-bottom: 0;
  }
  .spanData {
    color: #97eacc;
  }
`

const StyledAssetData = styled.div`
  font-weight: 800;
`

const StyledHarvest = styled(Button)`
  padding: 1px auto;
  max-width: 95px;
  align-self: flex-end;
  margin-bottom: 8px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  background-color: transparent;
  background-image: linear-gradient(94.19deg, #e000e4 4.18%, #14def5 88.14%);
  border-radius: 4px;
  border: none;
`

const StyledGain = styled.div`
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`

const StyledGainU = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
`

export default YieldItem
