import React from 'react'
import { IconProps } from '../Icon'

const SearchIcon: React.FC<IconProps> = ({ color, style }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" style={style}>
      <path d="M16 0H0V16H16V0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M6.99967 12.6667C10.1293 12.6667 12.6663 10.1296 12.6663 7.00004C12.6663 3.87044 10.1293 1.33337 6.99967 1.33337C3.87007 1.33337 1.33301 3.87044 1.33301 7.00004C1.33301 10.1296 3.87007 12.6667 6.99967 12.6667Z"
        stroke="#FEEAFF"
        strokeOpacity="0.8"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M8.88549 4.78103C8.40292 4.29847 7.73626 4 6.99989 4C6.26352 4 5.59686 4.29847 5.11426 4.78103"
        stroke="#FEEAFF"
        strokeOpacity="0.8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0742 11.074L13.9027 13.9024"
        stroke="#FEEAFF"
        strokeOpacity="0.8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SearchIcon
