// lib
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import BusinessButton from '../../../components/BusinessButton'
import { Button } from 'antd'

// hooks
import { useTranslation } from 'react-i18next'
import useMobile from '../../../hooks/useMobile'
import useModal from '../../../hooks/useModal'
import YieldDepositModal from './YieldDepositModal'
import YieldWithdrawModal from './YieldWithdrawModal'

interface BtnProps {
  isApprove: boolean
  pool: any
}

const YieldBtn: React.FC<BtnProps> = ({ pool, isApprove }) => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const [onDepositModal] = useModal(<YieldDepositModal poolInfo={pool} />, 'provider')
  const [onWithdrawModal] = useModal(<YieldWithdrawModal poolInfo={pool} />, 'provider')

  const handleDepositClick = useCallback(() => {
    onDepositModal()
  }, [onDepositModal])

  const handleWithdrawModal = useCallback(() => {
    onWithdrawModal()
  }, [onWithdrawModal])

  return (
    <StyleBtnWarp isMobile={isMobile}>
      {!isApprove ? (
        <StyledBtnBlock>
          <BusinessButton type={'Approve'} disabled={false} />
        </StyledBtnBlock>
      ) : (
        <>
          <StyledBtnBlock>
            <StyledWithBtn onClick={handleWithdrawModal}>Withdraw</StyledWithBtn>
          </StyledBtnBlock>
          <StyledBtnBlock>
            <BusinessButton type={'Deposit'} disabled={false} onClick={handleDepositClick} />
          </StyledBtnBlock>
        </>
      )}
    </StyleBtnWarp>
  )
}

interface MobileProp {
  isMobile: boolean
}

const StyleBtnWarp = styled.div<MobileProp>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  font-size: ${(prop) => (prop.isMobile ? '12px' : '14px')};
  font-weight: 700;
  text-align: center;
  .ant-btn[disabled] {
    color: rgba(100, 90, 105, 1);
    background: transparent;
    border: 1px solid #645a69;
  }
`

const StyledBtnBlock = styled.div`
  width: 360px;
  &:last-of-type {
    margin-left: 16px;
  }
`

const StyledWithBtn = styled(Button)`
  width: 100%;
  height: 40px;
  color: #19d9f5;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  background: transparent;
  border: 2px solid;
  border-image: linear-gradient(94.48deg, #e001e5 5.16%, #19d9f5 91.31%) 1;
  clip-path: inset(0 round 4px);
  &:hover,
  &:focus,
  &:active {
    color: #19d9f5;
    background: transparent;
  }
`

export default YieldBtn
