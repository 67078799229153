import React from 'react'
import styled from 'styled-components'

interface CardTitleProps {
  text?: string
  color?: string
  size?: number
}

const CardTitle: React.FC<CardTitleProps> = ({ text, size, color }) => (
  <StyledCardTitle size={size} color={color}>
    {text}
  </StyledCardTitle>
)

interface StyledCardTitleProps {
  size?: number
  color?: string
}

const StyledCardTitle = styled.div<StyledCardTitleProps>`
  color: ${(props) => props.color};
  font-size: ${(props) => props.color};
  font-weight: normal;
`

export default CardTitle
