import React from 'react'
import styled from 'styled-components'
import useMobile from '../../../hooks/useMobile'
import { useTranslation } from 'react-i18next'

interface TagProps {
  tagText: string
}

const TagComp: React.FC<TagProps> = ({ tagText }) => {
  const { t } = useTranslation()
  const isMobile = useMobile()

  return (
    <TagWrapper>
      <Line className="onLeft" />
      <TagText>{tagText}</TagText>
      <Line className="onRight" />
    </TagWrapper>
  )
}

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TagText = styled.div`
  font-weight: bold;
  font-size: 28px;
  color: #fff5ff;
  margin-left: 20px;
  margin-right: 20px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
  }
`

const Line = styled.div`
  border-radius: 2px;
  width: 160px;
  height: 2px;
  &.onLeft {
    background: linear-gradient(90deg, rgba(74, 186, 217, 0) 4.17%, #cf16e4 100%);
  }
  &.onRight {
    background: linear-gradient(90deg, #cf16e4 0%, rgba(74, 186, 217, 0) 100%);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: 60px;
  }
`

export default TagComp
