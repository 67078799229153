import React from 'react'
import { IconProps } from '../Icon'

const CopyIcon: React.FC<IconProps> = ({ color, size = 24 }) => {
  return (
    <svg width={size} height={size} style={{ verticalAlign: 'middle' }} viewBox="0 0 24 24" fill="none">
      <path
        d="M8.211 6.92037V5.10522C8.211 4.49482 8.70582 4 9.31622 4H18.8948C19.5052 4 20 4.49482 20 5.10522V14.6838C20 15.2942 19.5052 15.789 18.8948 15.789H17.0591"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6838 8.211H5.10522C4.49482 8.211 4 8.70582 4 9.31622V18.8948C4 19.5052 4.49482 20 5.10522 20H14.6838C15.2942 20 15.789 19.5052 15.789 18.8948V9.31622C15.789 8.70582 15.2942 8.211 14.6838 8.211Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CopyIcon
