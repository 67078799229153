import React from 'react'
import { IconProps } from '../Icon'

const SuccessNoticeIcon: React.FC<IconProps> = ({ color, size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M12 2C17.5229 2 22 6.47716 22 12C22 17.5229 17.5229 22 12 22C6.47716 22 2 17.5229 2 12C2 6.47716 6.47716 2 12 2Z"
        stroke="#3DD598"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6663 8.59187L16.2409 9.07396C16.5583 9.34006 16.5997 9.81313 16.3334 10.1304C16.3334 10.1305 16.3333 10.1305 16.3331 10.1305L11.3172 16.105C11.0507 16.422 10.5778 16.4633 10.2604 16.1973L9.11131 15.2331L14.6095 8.68419C14.876 8.36714 15.3489 8.32583 15.6663 8.59187Z"
        fill="#3DD598"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.18886 12.556L7.69208 11.9972C7.96487 11.6942 8.42971 11.6646 8.7387 11.9306L12.2664 14.9669L11.3066 16.1218C11.0419 16.4403 10.569 16.4839 10.2504 16.2192C10.2465 16.2159 10.2426 16.2126 10.2388 16.2093L7.25516 13.6248C6.94208 13.3536 6.90813 12.8799 7.17933 12.5668C7.18247 12.5632 7.18565 12.5596 7.18886 12.556Z"
        fill="#3DD598"
      />
    </svg>
  )
}

export default SuccessNoticeIcon
