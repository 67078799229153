import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Switch } from 'antd'
import { FilterIcon, SearchIcon, FarmArrowMobileIcon } from '../IconImage'
import useMobile from '../../hooks/useMobile'
import RefreshData from '../RefreshData'

const FiltersComp: React.FC = () => {
  const isMobile = useMobile()
  const onSwitchChange = useCallback((event: any) => {
    console.log(`switch to ${event}`)
  }, [])

  return (
    <>
      {!isMobile ? (
        <StyledFilterWarp>
          <StyledBlock>
            <StyledButton>
              <FilterIcon color={'#d8ced8'} />
              <StyledBtnText>Filters</StyledBtnText>
            </StyledButton>
            <StyledLine />
            <StyledInput isMobile={isMobile}>
              <SearchIcon style={{ verticalAlign: 'middle' }} />
              <input placeholder={'Search'} />
            </StyledInput>
          </StyledBlock>
          <StyledBlock>
            <StyledRefresh>
              <RefreshData refreshTime={30} />
            </StyledRefresh>
            <StyledSwitch>
              <StyledSwitchText>Stake only</StyledSwitchText>
              <Switch onChange={onSwitchChange} defaultChecked className={'switchBtn'} />
            </StyledSwitch>
          </StyledBlock>
        </StyledFilterWarp>
      ) : (
        <StyledMobileWarp>
          <StyledMobile>
            <StyledMItem>
              <span>Only stake</span>
              <FarmArrowMobileIcon style={{ verticalAlign: 'middle' }} />
            </StyledMItem>
            <StyledMItem>
              <span>Filter</span>
              <FarmArrowMobileIcon style={{ verticalAlign: 'middle' }} />
            </StyledMItem>
            <StyledMItem>
              <span>Sort</span>
              <FarmArrowMobileIcon style={{ verticalAlign: 'middle' }} />
            </StyledMItem>
          </StyledMobile>
          <StyledInput isMobile={isMobile}>
            <SearchIcon style={{ verticalAlign: 'middle' }} />
            <input placeholder={'Search'} />
          </StyledInput>
        </StyledMobileWarp>
      )}
    </>
  )
}

interface FiltersProp {
  isMobile: boolean
}

const StyledFilterWarp = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #d8ced8;
  z-index: 1;
`

const StyledBlock = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`

const StyledSwitch = styled.div`
  & > .switchBtn {
    width: 48px;
    vertical-align: middle;
    border: 1px solid rgba(254, 234, 255, 0.8);
  }
`

const StyledSwitchText = styled.span`
  margin-right: 10px;
  vertical-align: middle;
`

const StyledLine = styled.div`
  margin: 0 15px;
  width: 1px;
  height: 32px;
  background-color: rgba(254, 234, 255, 0.8);
`

const StyledButton = styled.div`
  width: 106px;
  height: 32px;
  line-height: 30px;
  border: 1px solid rgba(254, 234, 255, 0.8);
  border-radius: 4px;
  text-align: center;
`

const StyledBtnText = styled.span`
  margin-left: 12px;
`

const StyledInput = styled.div<FiltersProp>`
  padding: 0 15px;
  width: ${(props) => (props.isMobile ? '100%' : '230px')};
  height: 32px;
  background: #1f183c;
  border: 1px solid rgba(254, 234, 255, 0.8);
  border-radius: 4px;
  & > input {
    margin-left: 10px;
    width: ${(props) => (props.isMobile ? 'calc(100% - 50px)' : '160px')};
    height: 30px;
    line-height: 30px;
    border: none;
    outline: none;
    color: #d8ced8;
    font-size: 14px;
    background-color: transparent;
    vertical-align: middle;
  }
  & > input::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`

const StyledMobileWarp = styled.div`
  position: relative;
  z-index: 1;
`

const StyledMobile = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
`

const StyledMItem = styled.div`
  margin-right: 16px;
  & > span {
    font-size: 14px;
    vertical-align: middle;
    margin-right: 5px;
  }
`

const StyledRefresh = styled.div`
  margin-right: 10px;
  cursor: pointer;
`

export default FiltersComp
