import styled from 'styled-components'

export const LinkStyledButton = styled.div`
  text-decoration: none;
  background: none;

  cursor: pointer;
  font-weight: 500;

  :hover {
    text-decoration: none;
  }

  :active {
    text-decoration: none;
  }
`
