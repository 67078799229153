import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import contactImg from '../../../assets/img/contactUsImg.png'
import { ContactUsIcon } from 'components/IconImage'

import useMobile from '../../../hooks/useMobile'
import { useTranslation } from 'react-i18next'

const ContactUs: React.FC = () => {
  const { t } = useTranslation()
  const isMobile = useMobile()

  return (
    <ContactWrapper>
      <ContactLeftWrapper>
        <ContactTitle>SOH.COOL a new perspective to DeFi on Solana.</ContactTitle>
        <ContactDesc>Want to partner with SOH.COOL? Contact us.</ContactDesc>
        <StyledContactButton>
          <a className={'link'} href="mailto:contact@soh.cool">
            Contact Us
            <ContactUsIcon />
          </a>
        </StyledContactButton>
      </ContactLeftWrapper>
      <ContactRightWrapper>
        <ContactRightImg src={contactImg} />
      </ContactRightWrapper>
    </ContactWrapper>
  )
}

const ContactWrapper = styled.div`
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    align-items: center;
    flex-direction: column;
  }
  .link {
    color: #fff;
    text-decoration: none;
    font-weight: 800;
    font-size: 22px;
    white-space: pre;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &.active {
      color: #fff;
    }
    & > svg {
      margin-left: 10px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
      font-size: 14px;
      & > svg {
        width: 18px;
        height: 18px;
      }
    }
  }
`

const ContactLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: 80%;
    align-items: center;
  }
`

const ContactRightWrapper = styled.div`
  display: flex;
  justify-content: right;
  width: 60%;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: 100%;
    justify-content: center;
    margin-top: 64px;
  }
`

const ContactTitle = styled.div`
  font-weight: 800;
  font-size: 32px;
  color: #fff;
  white-space: pre-wrap;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    font-size: 16px;
  }
`

const ContactDesc = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #fff5ff;
  white-space: pre-wrap;
  margin-top: 24px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    font-size: 14px;
    margin-top: 12px;
  }
`

const StyledContactButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 218px;
  height: 62px;
  border: 2px solid transparent;
  border-radius: 8px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  margin-top: 40px;
  background-image: linear-gradient(to right, #07234e, #07234e), linear-gradient(141.04deg, #e001e5 0%, #19d9f5 99.28%);

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    border-radius: 4px;
    margin-top: 20px;
    width: 140px;
    height: 36px;
  }
`

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-weight: 800;
  font-size: 22px;
  white-space: pre;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &.active {
    color: #fff;
  }
  & > svg {
    margin-left: 10px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    font-size: 14px;
    & > svg {
      width: 18px;
      height: 18px;
    }
  }
`

const ContactRightImg = styled.img`
  width: 294px;
  height: 226px;
  object-fit: cover;
`

export default ContactUs
