import React, { useMemo, MouseEventHandler } from 'react'
import CardIcon from '../../CardIcon'
import CardTitle from '../../CardTitle'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useWallet, useConnection } from '@solana/wallet-adapter-react'
import Avatar from '../../../assets/img/avatar.png'
import { WalletName } from '@solana/wallet-adapter-wallets'
import { useWalletActionHandlers } from '../../../store/wallet/hooks'
import { NewWalletWeb3Connection } from '@soh-cool/client-sdk/dist/connection'
import * as globalStore from '@soh-cool/client-sdk/dist/globalStore'
// import { NewWalletWeb3Connection } from '../../../client-js/connection'
// import * as globalStore from '../../../client-js/globalStore'

interface WalletCardProps {
  icon: React.ReactNode
  isConnect?: boolean
  walletName: WalletName
  closeModal: () => void
}

const WalletCard: React.FC<WalletCardProps> = ({ icon, isConnect, walletName, closeModal }) => {
  const { wallet, connect, connected, select, publicKey, adapter } = useWallet()
  const { connection } = useConnection()
  const { t } = useTranslation()
  const { walletAddress, walletConnectState } = useWalletActionHandlers()

  const handleWalletConnect = () => {
    console.log('name', walletName)
    select(walletName)
    connect()
  }

  useMemo(async () => {
    if (connected && publicKey && adapter) {
      walletAddress(publicKey.toString())
      walletConnectState(connected)
      console.log('adapter', adapter)
      const web3Conn = NewWalletWeb3Connection(adapter as any, 'mainnet-beta', 'finalized')
      console.log('web3Conn', web3Conn)
      await globalStore.setConnection(web3Conn)
      // connection.getBalance(publicKey).then((res) => {
      //   console.log(res)
      // })
      closeModal()
    }
  }, [connected])

  return (
    <StyledButtonWrapper
      className={isConnect ? 'active' : ''}
      onClick={() => {
        handleWalletConnect()
      }}
    >
      <StyledButton className={isConnect ? 'active' : ''}>
        <StyledTextArea>
          {isConnect ? <StyledAvatar src={Avatar} /> : null}
          <CardTitle text={walletName} size={14} color={'#d8ced8'} />
        </StyledTextArea>
        <CardIcon>{icon}</CardIcon>
      </StyledButton>
    </StyledButtonWrapper>
  )
}

const StyledTextArea = styled.div`
  display: flex;
  align-items: center;
`

const StyledAvatar = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-right: 12px;
`

const StyledButtonWrapper = styled.div`
  margin-bottom: 14px;
  &.active {
    border: 2px solid;
    border-image: linear-gradient(94.48deg, #e001e5 5.16%, #19d9f5 91.31%) 1;
    clip-path: inset(0 round 4px);
  }
`

const StyledButton = styled.div`
  cursor: pointer;
  height: 64px;
  border-radius: 4px;
  background: #151422;
  color: #d8ced8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  &:hover,
  &:focus {
    color: #d8ced8;
    background: #393c50;
    border-color: #393c50;
  }
  &.active {
    background: #070707;
  }
`

export default WalletCard
