export const lightTheme = {
  breakpoints: {
    mobile: 576,
    xsMobile: 480,
  },
  spacing: {
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
  },
  topBarSize: 72,
  siteWidth: 1200,
}
