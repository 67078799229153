import React from 'react'
import { IconProps } from '../Icon'

const MediumIcon: React.FC<IconProps> = ({ color, size = 24 }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="url(#paint0_linear_3367_4920)" />
      <path
        d="M24 10C16.2688 10 10 16.2688 10 24C10 31.7313 16.2688 38 24 38C31.7313 38 38 31.7313 38 24C38 16.2688 31.7313 10 24 10ZM32 17.9281L30.725 19.15C30.6125 19.2344 30.5594 19.3719 30.5813 19.5063V28.4969C30.5594 28.6344 30.6125 28.7719 30.725 28.8531L31.975 30.075V30.3469H25.7V30.0875L26.9906 28.8344C27.1188 28.7063 27.1187 28.6688 27.1187 28.4781V21.2031L23.525 30.3156H23.0406L18.8594 21.2031V27.3125C18.8219 27.5688 18.9125 27.8281 19.0938 28.0125L20.775 30.0469V30.3188H16V30.0469L17.6812 28.0125C17.8625 27.8281 17.9406 27.5656 17.9 27.3125V20.25C17.9219 20.0531 17.8469 19.8625 17.6969 19.7281L16.2031 17.9281V17.6562H20.8438L24.425 25.5156L27.5781 17.6625H32V17.9281Z"
        fill="white"
      />
      <rect
        x="0.25"
        y="0.25"
        width="47.5"
        height="47.5"
        rx="7.75"
        stroke="url(#paint1_linear_3367_4920)"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient id="paint0_linear_3367_4920" x1="4" y1="3" x2="48" y2="48" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.13" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear_3367_4920" x1="2.5" y1="4" x2="46" y2="48" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A7A8A8" />
          <stop offset="0.536458" stopColor="white" stopOpacity="0.18" />
          <stop offset="1" stopColor="white" stopOpacity="0.9" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default MediumIcon
