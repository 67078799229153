import React from 'react'
import styled from 'styled-components'
import { Layout } from 'antd'
import useMobile from '../../hooks/useMobile'

const { Footer } = Layout

const SohFooter: React.FC = () => {
  const isMobile = useMobile()

  return (
    <>
      {/* {!isMobile ? (
        <Footer
          style={{
            position: 'fixed',
            bottom: '0',
            padding: '0',
            width: '100%',
            height: '72px',
            background: '#032551',
            borderTop: '1px solid',
            zIndex: '100',
            borderImage: 'linear-gradient(96.28deg, #493a49 15.42%, #e001e5 32.75%, #19d9f5 55.67%, #493a49 74.52%) 1',
          }}
        >
          <StyledFooterInner>
            <FooterText>Copyright©️2021 SOH.Cool. All Rights Reserved.</FooterText>
          </StyledFooterInner>
        </Footer>
      ) : null} */}
      <Footer
        style={{
          position: 'fixed',
          bottom: '0',
          padding: '0',
          width: '100%',
          height: '72px',
          background: '#032551',
          borderTop: '1px solid',
          zIndex: '100',
          borderImage: 'linear-gradient(96.28deg, #493a49 15.42%, #e001e5 32.75%, #19d9f5 55.67%, #493a49 74.52%) 1',
        }}
      >
        <StyledFooterInner>
          <FooterText>Copyright©️2022 SOH.COOL. All Rights Reserved.</FooterText>
        </StyledFooterInner>
      </Footer>
    </>
  )
}

const StyledFooterInner = styled.div`
  align-items: center;
  display: flex;
  justify-content: left;
  height: ${(props) => props.theme.topBarSize}px;
  max-width: ${(props) => props.theme.siteWidth}px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    justify-content: center;
  }
`

const FooterText = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #fff;
`

export default SohFooter
