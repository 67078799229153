import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import wrapperBg from '../../../assets/img/productBg.png'
import useMobile from '../../../hooks/useMobile'
import { useTranslation } from 'react-i18next'

interface ProductLinkProps {
  btnText: string
  link: string
  icon: React.ReactNode
  title: string
  desc: string
  disable: boolean
}

const ProductLink: React.FC<ProductLinkProps> = ({ btnText, link, icon, title, desc, disable }) => {
  const { t } = useTranslation()
  const isMobile = useMobile()

  return (
    <Wrapper>
      <IconWrapper>{icon}</IconWrapper>
      <Title>{title}</Title>
      <Desc>{desc}</Desc>
      <LinkBtnWrapper>
        <StyledLink to={!disable ? link : ''} className={disable ? 'disable' : ''}>
          {btnText}
        </StyledLink>
      </LinkBtnWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 320px;
  height: 355px;
  border-radius: 20px;
  background-image: url('${wrapperBg}');
  background-position: 0 -10px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const IconWrapper = styled.div`
  display: flex;
`

const Title = styled.div`
  font-weight: 800;
  font-size: 24px;
  color: #fff5ff;
  margin-top: 16px;
`

const Desc = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #d8ced8;
  margin-top: 10px;
  width: 180px;
  text-align: center;
`

const LinkBtnWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 45px;
  margin: auto;
  background: linear-gradient(92.64deg, #f22bf7 0.2%, #4ed4ff 95.95%);
  border-radius: 8px;
  width: 142px;
  height: 36px;
  text-align: center;
  margin-top: 24px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: auto;
    // height: 68px;
    border-radius: 4px;
  }
`

const StyledLink = styled(Link)`
  display: block;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  white-space: pre;
  padding: 7px 17px;
  width: 100%;
  height: 100%;

  &:hover,
  &.active {
    color: #fff;
  }
  &.disable {
    border-radius: 8px;
    background: #0e1e3e;
    color: #b8b8b8;
    cursor: not-allowed;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
      border-radius: 4px;
    }
  }
`

export default ProductLink
