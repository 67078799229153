import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

interface SpacerProps {
  size?: 'sm' | 'md' | 'lg'
  indexAttr?: 1 | 2 | 3 | 4 | 5 | 6 | 7
}

const Spacer: React.FC<SpacerProps> = ({ size = 'md', indexAttr }) => {
  const { spacing } = useContext(ThemeContext)

  let s: number
  if (indexAttr) {
    s = spacing[indexAttr]
  } else {
    switch (size) {
      case 'lg':
        s = spacing[6]
        break
      case 'sm':
        s = spacing[3]
        break
      case 'md':
        s = spacing[4]
        break
      default:
        s = spacing[5]
    }
  }

  return <StyledSpacer size={s} />
}

interface StyledSpacerProps {
  size: number
}

const StyledSpacer = styled.div<StyledSpacerProps>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
`

export default Spacer
