import React from 'react'
import styled from 'styled-components'

import { TwitterIcon, TelegramIcon, GithubIcon, DiscordIcon, MediumIcon } from '../../../components/IconImage'
import footerLogo from '../../../assets/img/logo.png'
import Container from '../../../components/Container'

import useMobile from '../../../hooks/useMobile'
import { useTranslation } from 'react-i18next'

const HomeFooter: React.FC = () => {
  const { t } = useTranslation()
  const isMobile = useMobile()

  const aboutList = [
    { label: 'Documentation', link: '/' },
    { label: 'Disclaimer', link: '/' },
    { label: 'FAQ', link: '/' },
  ]

  const productsList = [
    { label: 'Yield', link: '/yield' },
    { label: 'NFT', link: '/' },
    { label: 'Pool', link: '/' },
  ]

  const resourcesList = [
    { label: 'Logo & Brands', link: '/' },
    { label: 'SPL Wallet (WIP)', link: '/' },
    { label: 'LightPaper (WIP)', link: '/' },
  ]

  const communityList = [
    { label: 'Telegram', link: 'https://t.me/soh_cool_official' },
    { label: 'Twitter', link: 'https://twitter.com/SOH__COOL' },
    { label: 'Github', link: 'https://github.com/soh-cool' },
    { label: 'Discord', link: 'https://discord.gg/DP4YV9Rk' },
    { label: 'Medium', link: 'https://medium.com/@soh_cool' },
  ]

  return (
    <HomeFooterWrapper>
      <Container size="lg">
        <FooterLeft>
          <StyledLogo src={footerLogo} />
          <Title>SOH.COOL</Title>
          <MediaWrapper>
            <StyledLink target="_blank" href="https://t.me/soh_cool_official" key="telegram-link">
              <TelegramIcon />
            </StyledLink>
            <StyledLink target="_blank" href="https://twitter.com/SOH__COOL" key="twitter-link">
              <TwitterIcon />
            </StyledLink>
            <StyledLink target="_blank" href="https://github.com/soh-cool" key="github-link">
              <GithubIcon />
            </StyledLink>
            <StyledLink target="_blank" href="https://discord.gg/DP4YV9Rk" key="discord-link">
              <DiscordIcon />
            </StyledLink>
            <StyledLink target="_blank" href="https://medium.com/@soh_cool" key="medium-link">
              <MediumIcon />
            </StyledLink>
          </MediaWrapper>
        </FooterLeft>
        <FooterRight>
          <ListBlock>
            <ListWrapper>
              <ListTitle>About</ListTitle>
              {aboutList.map((item) => {
                return (
                  <ListItem>
                    <StyledLink target="_blank" href={item?.link} key={`${item?.label}-link`}>
                      {item?.label}
                    </StyledLink>
                  </ListItem>
                )
              })}
            </ListWrapper>
            <ListWrapper>
              <ListTitle>Products</ListTitle>
              {productsList.map((item) => {
                return (
                  <ListItem>
                    <StyledLink target="_blank" href={item?.link} key={`${item?.label}-link`}>
                      {item?.label}
                    </StyledLink>
                  </ListItem>
                )
              })}
            </ListWrapper>
          </ListBlock>
          <ListBlock>
            <ListWrapper>
              <ListTitle>Resources</ListTitle>
              {resourcesList.map((item) => {
                return (
                  <ListItem>
                    <StyledLink target="_blank" href={item?.link} key={`${item?.label}-link`}>
                      {item?.label}
                    </StyledLink>
                  </ListItem>
                )
              })}
            </ListWrapper>
            <ListWrapper>
              <ListTitle>Community</ListTitle>
              {communityList.map((item) => {
                return (
                  <ListItem>
                    <StyledLink target="_blank" href={item?.link} key={`${item?.label}-link`}>
                      {item?.label}
                    </StyledLink>
                  </ListItem>
                )
              })}
            </ListWrapper>
          </ListBlock>
        </FooterRight>
      </Container>
    </HomeFooterWrapper>
  )
}

const HomeFooterWrapper = styled.div`
  display: flex;
  background: #061f46;
  width: 100%;
  min-height: 410px;
  & > div {
    display: flex;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
      flex-direction: column;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    margin-top: 40px;
  }
`

const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 82.22px;
  width: 261px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: 100%;
    align-items: center;
  }
`

const FooterRight = styled.div`
  display: flex;
  margin-top: 82.22px;
  margin-left: 155px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: 100%;
    margin-left: 0px;
    flex-direction: column;
    margin-top: 40px;
    & > :nth-child(1) {
      margin-bottom: 20px;
      justify-content: center;
      margin-left: 40px;
    }
    & > :nth-child(2) {
      margin-bottom: 90px;
      justify-content: center;
      margin-left: 20px;
    }
  }
`

const ListBlock = styled.div`
  display: flex;
`

const StyledLogo = styled.img`
  width: 48px;
  height: 48px;
  object-fit: contain;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: 28px;
    height: 28px;
  }
`

const Title = styled.div`
  color: #fff5ff;
  font-weight: 800;
  font-size: 22px;
  margin-top: 20px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    font-size: 16px;
    margin-top: 2px;
  }
`

const Desc = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #fff5ff;
  margin-top: 18px;
`

const MediaWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 142px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    margin-top: 20px;
    margin-bottom: 0px;
  }
`

const StyledLink = styled.a`
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  & > svg {
    font-size: 14px;
    margin-right: 16px;
  }
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 83px;
`

const ListTitle = styled.div`
  font-weight: 800;
  font-size: 20px;
  color: #fff;
  margin-bottom: 30px;
`

const ListItem = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  margin-bottom: 16px;
`

export default HomeFooter
