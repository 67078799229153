import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Pagination } from 'antd'
import Container from '../../components/Container'
import useMobile from '../../hooks/useMobile'
import { useTranslation } from 'react-i18next'
import { ShareIcon } from 'components/IconImage'
import Avatar from '../../assets/img/logo.png'

const Notice: React.FC = () => {
  const { t } = useTranslation()
  const isMobile = useMobile()

  return (
    <Container size="lg" style={{ paddingBottom: isMobile ? '80px' : '' }}>
      <StyledNoticeWrapper>
        <StyledTitle>Notifications</StyledTitle>
        <StyledDesc>描述描述描述</StyledDesc>
        <StyledOperate>
          View On Explorer
          <ShareIcon color={'#19d9f5'} size={20} />
        </StyledOperate>
        <StyledContentWrapper>
          <StyledContent>
            <StyledContentTop>
              <StyledAvatar src={Avatar} />
              <StyledTextWrapper>
                <StyledText>Announcement</StyledText>
                <StyledDate>Today 12:21</StyledDate>
              </StyledTextWrapper>
            </StyledContentTop>
            <StyledContentBottom>
              <StyledContentText>
                2 days countdown to the Peaceful Elite Adventure World Design Contest! The competition will close at
                23:59 on November 14th! Design elites, the adventure world created by you is about to debut, and the
                future game world is all under your paint! There is also a prize pool of 240,000 event prizes and the
                "Peace All-round Photo Award" waiting for you to share! Little Z reminds "Remember to upload works in
                advance"! Little Z reminds "Remember to upload works in advance"! Last train of the event :
                <a className={'link'} target={'_blank'} href={'https://www.zcool.com.cn/event/hepingjingying/'}>
                  https://www.zcool.com.cn/event/hepingjingying/
                </a>
              </StyledContentText>
            </StyledContentBottom>
          </StyledContent>
          <StyledContent>
            <StyledContentTop>
              <StyledAvatar src={Avatar} />
              <StyledTextWrapper>
                <StyledText>Announcement</StyledText>
                <StyledDate>Today 12:21</StyledDate>
              </StyledTextWrapper>
            </StyledContentTop>
            <StyledContentBottom>
              <StyledContentText>
                2 days countdown to the Peaceful Elite Adventure World Design Contest! The competition will close at
                23:59 on November 14th! Design elites, the adventure world created by you is about to debut, and the
                future game world is all under your paint! There is also a prize pool of 240,000 event prizes and the
                "Peace All-round Photo Award" waiting for you to share! Little Z reminds "Remember to upload works in
                advance"! Little Z reminds "Remember to upload works in advance"! Last train of the event :
                <a className={'link'} target={'_blank'} href={'https://www.zcool.com.cn/event/hepingjingying/'}>
                  https://www.zcool.com.cn/event/hepingjingying/
                </a>
              </StyledContentText>
            </StyledContentBottom>
          </StyledContent>
        </StyledContentWrapper>
        <StyledPaginationWrapper>
          <Pagination size="small" total={50} />
        </StyledPaginationWrapper>
      </StyledNoticeWrapper>
    </Container>
  )
}

const StyledNoticeWrapper = styled.div`
  padding-top: 60px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: 100%;
    padding-top: 30px;
  }
`

const StyledTitle = styled.div`
  font-weight: 800;
  font-size: 64px;
  color: #fff5ff;
  line-height: 80px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    font-size: 32px;
  }
`

const StyledDesc = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-size: 20px;
  color: #d8ced8;
  line-height: 36px;
`

const StyledOperate = styled.div`
  margin: 8px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  color: #19d9f5;
  display: flex;
  align-items: center;
  justify-content: right;
  cursor: pointer;
`

const StyledContentWrapper = styled.div`
  padding: 1px;
  border-radius: 4px;
  background-color: #1b2142;
  background-clip: content-box, padding-box;
  background-image: linear-gradient(#1b2142, #1b2142), linear-gradient(96.2deg, #e001e5 2.5%, #19d9f5 99.28%);
`

const StyledContent = styled.div`
  padding: 32px;
  border-bottom: 2px solid rgba(254, 234, 255, 0.1);
`

const StyledContentTop = styled.div`
  display: flex;
  align-items: center;
`

const StyledAvatar = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`

const StyledTextWrapper = styled.div`
  margin-left: 24px;
`

const StyledText = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #fff5ff;
`

const StyledDate = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #b9b3b9;
`

const StyledContentBottom = styled.div``

const StyledContentText = styled.div`
  margin-top: 24px;
  margin-left: 64px;
  word-break: break-word;
  font-weight: 400;
  font-size: 14px;
  color: #d8ced8;
  .link {
    color: #fff5ff;
    font-weight: 600;
    text-decoration: underline;
  }
`

const StyledPaginationWrapper = styled.div`
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Notice
