import React from 'react'
import { IconProps } from '../Icon'

const ExChangeIcon: React.FC<IconProps> = ({ color, size = 50 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 50 50" fill="none">
      <g filter="url(#filter0_bi_2840_3632)">
        <rect width="50" height="50" rx="10" fill="url(#paint0_linear_2840_3632)" fillOpacity="0.2" />
        <rect x="1" y="1" width="48" height="48" rx="9" stroke="url(#paint1_linear_2840_3632)" strokeWidth="2" />
      </g>
      <g filter="url(#filter1_i_2840_3632)">
        <path
          d="M16.55 25.8501C16 25.8501 15.6 26.3001 15.6 26.9001V35.7001C15.6 36.3001 16.05 36.7501 16.55 36.7501C17.1 36.7501 17.5 36.3001 17.5 35.7001V26.9001C17.55 26.3501 17.1 25.8501 16.55 25.8501ZM22.35 23.9501C21.8 23.9501 21.4 24.4001 21.4 25.0001V35.7501C21.4 36.3001 21.85 36.8001 22.35 36.8001C22.9 36.8001 23.3 36.3501 23.3 35.7501V25.0001C23.3 24.4001 22.85 23.9501 22.35 23.9501ZM28.1 22.0501C27.55 22.0501 27.15 22.5001 27.15 23.0501V35.8001C27.15 36.3501 27.6 36.8001 28.1 36.8001C28.65 36.8001 29.05 36.3501 29.05 35.8001V23.0001C29.05 22.5001 28.65 22.0501 28.1 22.0501ZM33.85 18.2001C33.3 18.2001 32.9 18.6501 32.9 19.2501V35.7501C32.9 36.3001 33.35 36.8001 33.85 36.8001C34.4 36.8001 34.8 36.3501 34.8 35.7501V19.2501C34.8 18.6501 34.4 18.2001 33.85 18.2001ZM35.25 13.6501C35.1 13.4001 34.85 13.2001 34.55 13.2001H34.5C34.45 13.2001 34.35 13.2001 34.3 13.2001H29.45L31.3 15.0501L25.2 19.1001L22.15 17.1501C21.7 16.9001 21.25 17.0001 21 17.3501L17.95 20.4501L15.5 18.6001C15.05 18.3501 14.5 18.4501 14.3 18.8501C14.05 19.2501 14.2 19.7501 14.65 20.0501L17.65 22.3001C17.95 22.5001 18.25 22.4001 18.5 22.2001L18.55 22.1501L18.6 22.1001L21.75 18.9501L24.7 20.7501C25.05 20.9501 25.5 21.0001 25.75 20.8001C25.75 20.8001 25.8 20.8001 25.8 20.7501L34.7 14.8501C35.25 14.6501 35.45 14.1001 35.25 13.6501Z"
          fill="#E001E5"
        />
      </g>
      <defs>
        <filter
          id="filter0_bi_2840_3632"
          x="-100"
          y="-100"
          width="250"
          height="250"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="50" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2840_3632" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2840_3632" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2840_3632" />
        </filter>
        <filter
          id="filter1_i_2840_3632"
          x="14.1796"
          y="13.2001"
          width="21.1462"
          height="24.6"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2840_3632" />
        </filter>
        <linearGradient
          id="paint0_linear_2840_3632"
          x1="0"
          y1="24.8768"
          x2="50.8333"
          y2="24.8768"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E001E5" stopOpacity="0.6" />
          <stop offset="0.48854" stopColor="#E001E5" stopOpacity="0.31" />
          <stop offset="1" stopColor="#E001E5" stopOpacity="0.6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2840_3632"
          x1="3"
          y1="2.5"
          x2="56.4098"
          y2="41.1401"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#19D9F5" stopOpacity="0.8" />
          <stop offset="0.40625" stopColor="#E001E5" stopOpacity="0" />
          <stop offset="0.697917" stopColor="#E001E5" stopOpacity="0" />
          <stop offset="1" stopColor="#E001E5" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ExChangeIcon
