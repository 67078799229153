import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import { refreshCount } from './action'
import { useWallet, useConnection } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'

export function useCompYieldState(): AppState['compYield'] {
  return useSelector<AppState, AppState['compYield']>((state) => state.compYield)
}

export function useCompYieldActionHandlers(): {
  refreshDataCount: () => void
} {
  const dispatch = useDispatch<AppDispatch>()
  const refreshDataCount = useCallback(() => {
    dispatch(refreshCount())
  }, [dispatch])

  return {
    refreshDataCount,
  }
}
