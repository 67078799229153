import React from 'react'
import styled from 'styled-components'
import { FarmIcon } from '../IconImage'
import useMobile from '../../hooks/useMobile'

interface CoinName {
  coin: string
}

const GetCoin: React.FC<CoinName> = ({ coin }) => {
  const isMobile = useMobile()
  return (
    <StyledCoin>
      <FarmIcon style={{ verticalAlign: 'middle' }} /> <StyledSpan isMobile={isMobile}>Get {coin}</StyledSpan>
    </StyledCoin>
  )
}

interface MobileProp {
  isMobile: boolean
}

const StyledCoin = styled.div`
  color: #19d9f5;
  line-height: 28px;
  cursor: pointer;
`

const StyledSpan = styled.span<MobileProp>`
  vertical-align: middle;
  font-size: ${(props) => (props.isMobile ? '12px' : '14px')};
`

export default GetCoin
