import React from 'react'
import styled from 'styled-components'

import wrapperBg from '../../../assets/img/PCdappDesc.png'
import mobileWrapperBg from '../../../assets/img/mobileDappDesc.png'

import useMobile from '../../../hooks/useMobile'
import { useTranslation } from 'react-i18next'

interface DappDescProps {
  text: string
}

const DappDesc: React.FC<DappDescProps> = ({ text }) => {
  const { t } = useTranslation()
  const isMobile = useMobile()

  return (
    <Wrapper>
      <Text>{text}</Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 1040px;
  // height: 225px;
  border-radius: 20px;
  background-image: url('${wrapperBg}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  padding: 30px 32px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: 340px;
    // height: 416px;
    background-size: contain;
    background-image: url('${mobileWrapperBg}');
    background-size: 100% 100%;
    padding: 14px 17px;
    background-position: top center;
  }
`

const Text = styled.span`
  font-weight: 600;
  font-size: 16px;
  color: #fff5ff;
  line-height: 32px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }
`

export default DappDesc
