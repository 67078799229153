import React, { useMemo } from 'react'
import styled from 'styled-components'
import Modal, { ModalProps } from '../Modal'
import ModalContent from '../ModalContent'
import ModalTitle from '../ModalTitle'
import { useTranslation } from 'react-i18next'
import useMobile from '../../hooks/useMobile'
import { CloseIcon } from '../IconImage'
import WalletCard from './components/WalletCard'
import { useWallet } from '@solana/wallet-adapter-react'

const SelectWalletModal: React.FC<ModalProps> = ({ onDismiss, maxWidth = 420 }) => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const { wallets } = useWallet()

  const handleCloseModal = () => {
    onDismiss!()
  }

  console.log('list', wallets)

  return (
    <SelectWalletModalWrapper
      className={isMobile ? 'center-body' : ''}
      style={isMobile ? { width: '100%' } : { minWidth: '420px' }}
    >
      <Modal showBorder={true} maxWidth={maxWidth}>
        <ModalTitle>
          <StyledTitle>Select a Wallet</StyledTitle>
          <StyledCloseIconWrapper onClick={() => onDismiss!()}>
            <CloseIcon color="#B0B1B8" size={24} />
          </StyledCloseIconWrapper>
        </ModalTitle>
        <ModalContent>
          {wallets &&
            wallets.map((wallet) => (
              <WalletCard
                isConnect={false}
                icon={<img src={wallet.icon} alt={`${wallet.name} icon`} />}
                walletName={wallet.name}
                closeModal={handleCloseModal}
                key={wallet.name}
              />
            ))}
        </ModalContent>
      </Modal>
    </SelectWalletModalWrapper>
  )
}

const StyledWalletImg = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
`

const StyledCloseIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const StyledTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #fff5ff;
`

const SelectWalletModalWrapper = styled.div`
  &.center-body > div {
    justify-content: center;
    position: relative;
    max-height: initial;
    margin: 0 auto;
  }
`

export default SelectWalletModal
