import React, { useState } from 'react'
import { IconProps } from '../Icon'
import noIconImg from '../../assets/img/help.png'

export interface CoinIconProps extends IconProps {
  coinAddr: string
  round?: boolean
}

const CoinIcon: React.FC<CoinIconProps> = ({ coinAddr, round = true, style }) => {
  const [isShow, setIsShow] = useState<boolean>(true)
  const imgSpecificStyle = {
    borderRadius: round ? '50%' : 'unset',
  }
  const imgStyle = { ...style, ...imgSpecificStyle }
  const coinIcon = `https://sdk.raydium.io/icons/${coinAddr}.png`
  return (
    <img
      src={isShow ? coinIcon : noIconImg}
      alt="icon"
      style={imgStyle}
      onError={() => {
        setIsShow(false)
      }}
    />
  )
}

export default CoinIcon
