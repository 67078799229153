import React from 'react'
import { IconProps } from '../Icon'

const TwitterIcon: React.FC<IconProps> = ({ color, size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <rect width="24" height="24" rx="4" fill="url(#paint0_linear_2657_15602)" />
      <rect
        x="0.25"
        y="0.25"
        width="23.5"
        height="23.5"
        rx="3.75"
        stroke="url(#paint1_linear_2657_15602)"
        strokeWidth="0.5"
      />
      <path
        d="M17.5665 9.48746C17.5748 9.59624 17.5748 9.70508 17.5748 9.81386C17.5822 10.7593 17.3759 11.6966 16.9679 12.5713C16.5599 13.4461 15.9582 14.2409 15.1979 14.9095C14.4376 15.5781 13.5337 16.1072 12.5389 16.466C11.544 16.8249 10.478 17.0063 9.40273 16.9998C7.84204 17.0016 6.31369 16.6087 5 15.8679C5.22692 15.8916 5.45522 15.9037 5.68375 15.9041C6.97736 15.9049 8.23373 15.5233 9.25097 14.8206C8.6517 14.8106 8.07096 14.6362 7.58992 14.3218C7.10888 14.0075 6.75156 13.5687 6.56792 13.0671C6.9982 13.14 7.4418 13.1252 7.8645 13.0236C7.21453 12.9081 6.63002 12.5984 6.21013 12.1471C5.79025 11.6959 5.56085 11.1308 5.56086 10.5477V10.5159C5.96041 10.7116 6.40764 10.82 6.86485 10.832C6.25677 10.4747 5.82637 9.92712 5.66119 9.30067C5.49602 8.67422 5.6085 8.016 5.97573 7.45987C6.69679 8.24006 7.59646 8.8781 8.6163 9.33263C9.63613 9.78716 10.7533 10.048 11.8952 10.0981C11.7528 9.55685 11.817 8.98942 12.0781 8.48349C12.3392 7.97757 12.7826 7.56136 13.3397 7.2992C13.8967 7.03704 14.5365 6.94349 15.1601 7.03309C15.7837 7.12269 16.3564 7.39041 16.7896 7.79485C17.4327 7.68299 18.0494 7.47554 18.6132 7.18137C18.3984 7.76598 17.9497 8.26226 17.3504 8.57813C17.9198 8.51917 18.4759 8.38513 19 8.18065C18.6143 8.6877 18.1289 9.13024 17.5665 9.48746Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear_2657_15602" x1="2" y1="1.5" x2="24" y2="24" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.13" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear_2657_15602" x1="1.25" y1="2" x2="23" y2="24" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A7A8A8" />
          <stop offset="0.536458" stopColor="white" stopOpacity="0.18" />
          <stop offset="1" stopColor="white" stopOpacity="0.9" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default TwitterIcon
