import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import useMobile from '../../hooks/useMobile'
import { Progress, Tooltip } from 'antd'
import { useCompYieldActionHandlers } from '../../store/compYield/hooks'

interface RefreshProp {
  refreshTime: number
}

const RefreshData: React.FC<RefreshProp> = ({ refreshTime }) => {
  const isMobile = useMobile()
  const [percentTime, setPercentTime] = useState<number>(0)
  const { refreshDataCount } = useCompYieldActionHandlers()
  const interval = useRef<any>()
  let nowTime = percentTime

  const setMarketTimer = () => {
    const marketTimer = setInterval(() => {
      if (percentTime < refreshTime) {
        setPercentTime(nowTime++)
        if (nowTime === refreshTime) {
          nowTime = 0
          setPercentTime(0)
          refreshDataCount()
        }
      }
    }, 1000)
    interval.current = marketTimer
  }

  const handleRefreshClick = () => {
    clearInterval(interval.current)
    nowTime = 1
    setPercentTime(0)
    refreshDataCount()
    setMarketTimer()
  }
  console.log('percentTime', percentTime)

  useEffect(() => {
    setMarketTimer()
    return () => {
      clearInterval(interval.current)
    }
  }, [])

  return (
    <>
      <StyledProgressWarp onClick={handleRefreshClick}>
        <Tooltip
          placement={'bottomRight'}
          title={`Displayed data will auto-refresh after ${
            refreshTime - percentTime
          } seconds. Click this circle to update manually.`}
        >
          <Progress
            type={'circle'}
            width={20}
            strokeWidth={10}
            showInfo={false}
            percent={(100 / refreshTime) * percentTime}
            strokeColor={'#E001E5'}
          />
        </Tooltip>
      </StyledProgressWarp>
    </>
  )
}

const StyledProgressWarp = styled.div``

export default RefreshData
